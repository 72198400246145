/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CUSTOMER_DETAILS, SET_CUSTOMER_DETAILS } from "../actions";
import { apiUrl, uniqueID } from "../components/Settings/Config";
import Axios from "axios";

export const watchGetCustomerDetails = function* () {
  yield takeEvery(GET_CUSTOMER_DETAILS, workerGetCustomerDetails);
};

function* workerGetCustomerDetails(reqData) {
  try {
    var uniqueIDCond = reqData?.Unquie_ID ? reqData?.Unquie_ID : uniqueID;
    const uri =
      apiUrl +
      "customer/customerdetail?uniqueID=" +
      uniqueIDCond +
      "" +
      reqData.params;
    const result = yield call(Axios.get, uri, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CUSTOMER_DETAILS, value: resultArr });
  } catch (e) {
    let returnerror = [];
    returnerror.push(e?.response?.data || []);
    yield put({ type: SET_CUSTOMER_DETAILS, value: returnerror });
  }
}
