import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import cookie from "react-cookies";
import Swal from "sweetalert2";
import { GET_CUSTOMER_DETAILS } from "../../actions";
import { apiUrl, uniqueID, baseUrl } from "../Settings/Config";
import {
  encodeValue,
  hideLoader,
  showAlert,
  showLoader,
  stripslashes
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import voucyellow from "../../common/images/voucher-yellow.svg";
import plus from "../../common/images/plus-sign.svg";
import minus from "../../common/images/minus-sign.svg";
import reloadQr from "../../common/images/reload_qr.png";

import googlePay from "../../common/images/payment/google-pay.svg";
import amexPay from "../../common/images/payment/american-express.svg";
import applePay from "../../common/images/payment/apple_logo.svg";
import atome from "../../common/images/payment/atome.svg";
import paynow from "../../common/images/payment/PayNowLogo.svg";
import grabpay from "../../common/images/payment/GrabPay.svg";
import GrabPaywhite from "../../common/images/payment/GrabPay-white.png";

import mastercard from "../../common/images/payment/mastercard.svg";
import visa from "../../common/images/payment/visa.svg";
import qrcodepay from "../../common/images/payment/qr-code-pay.svg";
import mswipe from "../../common/images/payment/mswipe.svg";

var qs = require("qs");
var Parser = require("html-react-parser");
var downloadTimer = "";
class Checkout extends Component {
  constructor(props) {
    super(props);
    var voucherSlug = this.props.location.state?.voucherSlug || "";
    var quantity = this.props.location.state?.quantity || 1;
    var checkoutType = this.props.location.state?.checkoutType || "topup";

    var planData =
      localStorage.getItem("planData") === null
        ? ""
        : localStorage.getItem("planData");
    planData = planData !== "" ? JSON.parse(planData) : [];
    var UserId = cookie.load("UserId") || "";
    var outletID = cookie.load("outletID") || "";
    this.state = {
      voucherSlug: voucherSlug,
      checkoutType: checkoutType,
      current_page: checkoutType === "topup" ? "Checkout" : "Checkout Voucher",
      backLink:
        checkoutType === "topup" ? "myaccount" : "buy-vouchers/" + voucherSlug,
      UserId: UserId,
      customerData: [],
      plan_data: planData,
      plan_qty: quantity,
      plan_subtotal: 0,
      plan_gst: 0,
      plan_gst_amount: 0,
      plan_grandtotal: 0,
      terms_conditions: "no",
      promotions_updates: "no",
      error_msg: "",
      stripe_loaded: 0,
      stripe_log: 0,
      qrcodeData: [],
      qrcode_str: "",
      qrCode: "",
      runingNum: 0,
      inititalLoad: true,
      productList: [],
      productName: "",
      updatedQty: false,
      startbuyVoucher: false,
      paymentMethod: "MSwipe",
      manualPayment: false,
      mswipePayment: false,
      reddotPayment: false,
      savecard: false,
      outletList: "",
      outletID: outletID,
      temppaymentMethod:"",
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
    if (checkoutType === "topup") {
      if (Object.keys(planData).length > 0) {
      } else {
        props.history.push("/topup");
      }
    } else if (checkoutType === "buyvoucher") {
      if (voucherSlug === "") {
        props.history.push("/buy-vouchers");
      }
    }
    this.submitRef = React.createRef();

    if (cookie.load("IsVerifiedUser") !== "Yes") {
      cookie.save("triggerOTP", "Yes", { path: "/" });
      cookie.save("triggerFrom", "checkout", { path: "/" });
      if (checkoutType === "topup") {
        props.history.push("/");
      } else if (checkoutType === "buyvoucher") {
        var details = {
          voucherSlug: voucherSlug,
          quantity: quantity,
          checkoutType: checkoutType,
          triggerFrom: "/checkout",
        };
        this.props.history.push({
          pathname: "/",
          state: details,
        });
      }
    }

    this.props.getCustomerDetails("&customer_id=" + UserId);
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    if (this.state.checkoutType === "topup") {
      this.loadPlanData();
    }
  }

  componentWillReceiveProps(PropsDt) {
    if (this.state.customerData !== PropsDt.customerdetails) {
      this.setState({ customerData: PropsDt.customerdetails }, () => {
        console.log(PropsDt.customerdetails, 'PropsDt.customerdetails')
        var custDetails = Object.keys(PropsDt.customerdetails);
        if (
          this.state.checkoutType === "buyvoucher" &&
          custDetails.length > 0
        ) {
          this.loadVoucherDetails( "product_slug=" + this.state.voucherSlug);
        }
      });
    }
  }


  loadVoucherDetails(params) {
    axios
      .get(
        apiUrl + "product/voucherDetails?"+params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState(
            { productList: [res.data.result] }, () => {
              this.loadProduct();
              if (this.state.checkoutType === "buyvoucher") {
                this.loadSettings();
              }
            }
          );
        }
      }).catch((e)=> {
        console.log(e)
      });
  }


  loadSettings() {
    var uniqueIDs = uniqueID;
    if (this.state.checkoutType === "buyvoucher") {
      let productList = this.state.productList[0];
      console.log(this.state.productList, productList, 'productListproductList')
      uniqueIDs = productList.productCompanyUniqueID;
    }
    var urlShringTxt = apiUrl + "settings/getSettings/?uniqueID=" + uniqueIDs;
    axios.get(urlShringTxt, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      if (res.data.status === "ok") {
        var result = res.data.result;
        var manualPayment = result?.companyenablemanualpayment
          ? Boolean(result?.companyenablemanualpayment)
          : false;
        var paymentMethod = "";

        var mswipePayment = result?.companyEnablemswipe || false;

        var reddotPayment = result?.companyenablereddot || false;
        var reddotmv3ds = reddotPayment===true ? result?.companyreddotEnablemv3ds || false : false;
        if (paymentMethod === "" && reddotmv3ds === true) {
          paymentMethod = "mv3ds";
        }
        var reddotamex3ds = reddotPayment===true ? result?.companyreddotEnableamex3ds || false : false;
        if (paymentMethod === "" && reddotamex3ds === true) {
          paymentMethod = "amex3ds";
        }
        var reddotapplepay = reddotPayment===true ? result?.companyreddotEnableapplepay || false : false;
        if (paymentMethod === "" && reddotapplepay === true) {
          paymentMethod = "applepay";
        }
        var reddotatome = reddotPayment===true ? result?.companyreddotEnableatome || false : false;
        if (paymentMethod === "" && reddotatome === true) {
          paymentMethod = "atome";
        }
        var reddotgooglepay = reddotPayment===true ? result?.companyreddotEnablegooglepay || false : false;
        if (paymentMethod === "" && reddotgooglepay === true) {
          paymentMethod = "googlepay";
        }
        var reddotgrabpay = reddotPayment===true ? result?.companyreddotEnablegrabpay || false : false;
        if (paymentMethod === "" && reddotgrabpay === true) {
          paymentMethod = "grabpay";
        }
        var reddotpaynow = reddotPayment===true ? result?.companyreddotEnablepaynow || false : false;
        if (paymentMethod === "" && reddotpaynow === true) {
          paymentMethod = "paynow";
        }

        if (paymentMethod === "" && mswipePayment === true) {
          paymentMethod = "MSwipe";
        }
        if (paymentMethod === "" && manualPayment === true) {
          paymentMethod = "QR Payment";
        }
        if (
          navigator.userAgent.indexOf("Mac") !== -1 &&
          reddotapplepay === true
        ) {
          paymentMethod = "applepay";
        }
        this.setState({
          manualPayment: manualPayment,
          mswipePayment: mswipePayment,
          reddotPayment: reddotPayment,
          paymentMethod: paymentMethod,
          reddotmv3ds: reddotmv3ds,
          reddotamex3ds: reddotamex3ds,
          reddotapplepay: reddotapplepay,
          reddotatome: reddotatome,
          reddotgooglepay: reddotgooglepay,
          reddotgrabpay: reddotgrabpay,
          reddotpaynow: reddotpaynow,
        });
        if(this.state.outletID==="") {
          this.getOutlet();
        }
      }
    }).catch((e) => {
      console.error("Error fetching data:", e);
    });
  }

  getOutlet() {
    var urlShringTxt = apiUrl + "outlet/dropdownlist/?uniqueID=" + uniqueID;
    axios
      .get(urlShringTxt, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var result = res.data.result;
          if (result.length > 0) {
            if (result.length > 1) {
              var outletList = '<option value="">Select Outlet</option>';
              result.map((item) => {
                outletList += `<option value="${item.value}">${item.label}</option>`;
                return '';
              });
              this.setState({ outletList: outletList });
            } else {
              cookie.save("outletID", result[0].value, { path: "/" });
            }
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  loadProduct(loadType = "") {
    if (this.state.productList.length > 0) {
      var productList = this.state.productList[0];
      var planGst = this.state.tax_percentage;
      var planSubtotal =
        parseFloat(productList.productPrice) * parseInt(this.state.plan_qty);
      var planGstAmount =
        parseFloat(this.state.tax_percentage) > 0
          ? (planGst / 100) * planSubtotal
          : 0;
      var planGrandtotal = planSubtotal + planGstAmount;
      this.setState({
        plan_subtotal: planSubtotal,
        tax_percentage: planGst,
        plan_gst_amount: planGstAmount,
        plan_grandtotal: planGrandtotal,
        productName:
          productList.productAliasName !== "" && productList.productAliasName !== null
            ? stripslashes(productList.productAliasName)
            : stripslashes(productList.productName),
        error_msg: "",
      });
    }
  }

  loadPlanData() {
    let planData = this.state.plan_data;
    let planQty = this.state.plan_qty;
    let planSubtotal = this.state.plan_subtotal;
    //let planGst = this.state.plan_gst;
    let planGst = this.state.tax_percentage;
    let planGstAmount = this.state.plan_gst_amount;
    let planGrandtotal = this.state.plan_grandtotal;
    if (Object.keys(planData).length > 0) {
      if (parseInt(planQty) > 0) {
        planGst = planData.tax_percentage;
        let topupplanCreditsAmount = planData.topupplan_credits_amount;
        topupplanCreditsAmount =
          topupplanCreditsAmount !== ""
            ? parseFloat(topupplanCreditsAmount)
            : 0;
        if (topupplanCreditsAmount > 0) {
          planSubtotal = topupplanCreditsAmount * parseInt(planQty);
          planGstAmount = (planGst / 100) * planSubtotal;
          planGrandtotal = planSubtotal + planGstAmount;
          this.setState({
            plan_subtotal: planSubtotal,
            tax_percentage: planGst,
            plan_gst_amount: planGstAmount,
            plan_grandtotal: planGrandtotal,
            error_msg: "",
          });
          if (this.state.checkoutType === "topup") {
            this.loadSettings();
          }
        } else {
          this.props.history.push("/topup");
        }
      } else {
        localStorage.removeItem("planData");
        this.props.history.push("/topup");
      }
    } else {
      this.props.history.push("/topup");
    }
  }

  plnQtyAction(actionFlg) {
    let planQty = this.state.plan_qty;
    planQty = parseInt(planQty);
    if (actionFlg === "decr") {
      planQty = planQty > 0 ? planQty - 1 : planQty;
    } else {
      planQty = planQty + 1;
    }
    this.setState({ plan_qty: planQty, error_msg: "" }, () => {
      if (this.state.checkoutType === "topup") {
        this.loadPlanData();
      } else if (this.state.checkoutType === "buyvoucher") {
        this.setState({ updatedQty: true }, () => {
          this.loadProduct("updqty");
        });
      }
    });
  }
  rvmQtyAction(event) {
    event.preventDefault();
    let planQty = 0;
    this.setState({ plan_qty: planQty, error_msg: "" }, () => {
      if (this.state.checkoutType === "topup") {
        this.loadPlanData();
      } else if (this.state.checkoutType === "buyvoucher") {
        this.props.history.push("/buy-vouchers");
      }
    });
  }

  checkboxInfoFld(fieldNm) {
    let terms_conditions = this.state.terms_conditions;
    let promotions_updates = this.state.promotions_updates;
    if (fieldNm === "terms") {
      terms_conditions = terms_conditions === "yes" ? "no" : "yes";
      this.setState({ terms_conditions: terms_conditions, error_msg: "" });
    }
    if (fieldNm === "promotions") {
      promotions_updates = promotions_updates === "yes" ? "no" : "yes";
      this.setState({ promotions_updates: promotions_updates, error_msg: "" });
    }
    if (fieldNm === "savecard") {
      this.setState({ savecard: !this.state.savecard });
    }
  }

  proceedPayment() {
    var outletID = cookie.load("outletID") || "";
    if(outletID==="") {
      Swal.fire({
        title: "Error",
        html: "Please select any one outlet",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary waves-effect waves-light",
        },
        buttonsStyling: false,
      });
      return false;
    }
    if (this.state.checkoutType === "topup") {
      this.placeOrderValidation();
    } else if (this.state.checkoutType === "buyvoucher") {
      this.buyVoucher();
    }
  }

  placeOrderValidation() {
    let plan_data = this.state.plan_data;
    let customer_id = this.state.UserId;
    let plan_id = encodeValue(plan_data.topupplan_id);
    let plan_qty = this.state.plan_qty;
    let subtotal_amount = this.state.plan_subtotal;
    let total_amount = this.state.plan_grandtotal;
    let tax_percentage = this.state.tax_percentage;
    let terms_conditions = this.state.terms_conditions;
    let promotions_updates = this.state.promotions_updates;

    let isErrorMsg = "";
    if (customer_id === "") {
      isErrorMsg = "Customer Id was empty";
    } else if (plan_id === "") {
      isErrorMsg = "Plan Id was empty";
    } else if (plan_qty === 0 || plan_qty === "") {
      isErrorMsg = "Plan Quantity was empty";
    } else if (subtotal_amount === 0 || subtotal_amount === "") {
      isErrorMsg = "Subtotal amount was empty";
    } else if (total_amount === 0 || total_amount === "") {
      isErrorMsg = "Total amount was empty";
    } else if (terms_conditions === "no") {
      isErrorMsg = "Please accept terms & conditions";
    }

    if (isErrorMsg === "") {
      showLoader("placeOredr");
      this.setState({ error_msg: "" });
      var postObject = {
        uniqueID: uniqueID,
        customer_id: customer_id,
        productID: plan_id,
        plan_qty: plan_qty,
        subtotal_amount: subtotal_amount,
        total_amount: total_amount,
        tax_percentage: tax_percentage,
        terms_conditions: terms_conditions,
        promotions_updates: promotions_updates,
        checkoutType: this.state.checkoutType,
        callbackurl: baseUrl,
        savecard: this.state.savecard === true ? "Y" : "N",
        paymentMethod: this.state.paymentMethod,
        outletID:cookie.load("outletID") || ''
      };
      window.sessionStorage.setItem("postObject", qs.stringify(postObject));
      if (this.state.paymentMethod === "Reddot") {
        axios
          .post(
            apiUrl + "payment/processReddotPayment",
            qs.stringify(postObject),
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            hideLoader("placeOredr");
            if (res.data.status === "ok") {
              localStorage.setItem(
                "paymentRequestIdRedtod",
                res.data.payment_requestId
              );
              window.location.href = res.data.redirectURL;
            } else {
              showAlert("Alert", res.data.message, "error");
            }
          }).catch((e) => {
            console.error("Error fetching data:", e);
          });
      } else if (this.state.paymentMethod === "MSwipe") {
        var postObjects = {
          uniqueID: uniqueID,
          qr_type: this.state.checkoutType,
          customer_id: this.state.UserId,
          payment_type: this.state.checkoutType,
          callbackurl: baseUrl + "placeorder",
          paid_amount: total_amount,
        };

        axios
          .post(
            apiUrl + "paymentV2/createPaymentLink",
            qs.stringify(postObjects),
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            hideLoader("placeOredr");
            if (res.data.status === "ok") {
              localStorage.setItem(
                "paymentRequestId",
                res.data.payment_requestId
              );
              window.location.href = res.data.redirectURL;
            }
          }).catch((e) => {
            console.error("Error fetching data:", e);
          });
      }
    } else {
      this.setState({ error_msg: isErrorMsg });
    }
  }

  generateCustQrcode(planID, unquie_ID = "") {

    var postObject = {};
    postObject = {
      uniqueID: unquie_ID !== "" ? unquie_ID : uniqueID,
      QRType: this.state.checkoutType,
      billAmount: '',
      voucherID:planID,
      voucherQty:this.state.plan_qty,
    };
  
    axios
      .post(
        apiUrl + "customerQR/generateQR",
         postObject, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          console.log(res.data.QRImage, 'res.data.QRImage')
          this.setState(
            {
              qrcode_str: res.data.QRImage,
              qrCode: res.data.QRCode,
              runingNum: 25,
            },
            function () {
              console.log(this.state.qrcode_str, this.state.qrCode,'res.data.QRImage')
              this.setRuningNum();
              /*  if (this.state.inititalLoad === true) {
                this.setState({ inititalLoad: false }, () => { */
              this.checkingQRstatus();
              /*   });
              } */
            }.bind(this)
          );
        }
      }).catch((e) => {
        console.error("Error fetching data:", e);
      });
  }

  setRuningNum() {
    var rct_this = this;
    var runingNum = this.state.runingNum;
    downloadTimer = setInterval(function () {
      if (runingNum <= 0) {
        clearInterval(downloadTimer);
      }
      runingNum = runingNum - 1;
      rct_this.setState({ runingNum: runingNum });
    }, 1000);
  }

  reloadQrFun(event) {
    event.preventDefault();
    if (this.state.checkoutType === "buyvoucher") {
      let productList = this.state.productList[0];
      this.generateCustQrcode(
        productList._id,
        productList.productCompanyUniqueID
      );
    } else {
      this.generateCustQrcode(this.state.plan_data.topupplan_id);
    }
  }

  checkingQRstatus() {
    const postObject = {
      uniqueID: uniqueID,
      qrCode: this.state.qrCode
    };
    axios
    .post(apiUrl + "customerQR/checkQRStatus", postObject, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        var currentThis = this;
        if (res.data.status === "ok") {
          if (this.state.runingNum > 0) {
            setTimeout(function () {
              currentThis.checkingQRstatus();
            }, 3000);
          }
        } else if (res.data.status === "used") {
          Swal.fire({
            title: "Success",
            html: "Voucher buy successful",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });

          this.props.history.push("/myaccount");
          /* this.buyVoucher(); */
        } else {
        }
      }).catch((e) => {
        console.error("Error fetching data:", e);
      });
  }

  placeOrder() {
    let plan_data = this.state.plan_data;
    let customer_id = this.state.UserId;
    let plan_id = encodeValue(plan_data.topupplan_id);
    let plan_qty = this.state.plan_qty;
    let subtotal_amount = this.state.plan_subtotal;
    let total_amount = this.state.plan_grandtotal;
    /* let tax_percentage = this.state.tax_percentage; */
    let terms_conditions = this.state.terms_conditions;
    /*  let promotions_updates = this.state.promotions_updates; */

    let isErrorMsg = "";
    if (customer_id === "") {
      isErrorMsg = "Customer Id was empty";
    } else if (plan_id === "") {
      isErrorMsg = "Plan Id was empty";
    } else if (plan_qty === 0 || plan_qty === "") {
      isErrorMsg = "Plan Quantity was empty";
    } else if (subtotal_amount === 0 || subtotal_amount === "") {
      isErrorMsg = "Subtotal amount was empty";
    } else if (total_amount === 0 || total_amount === "") {
      isErrorMsg = "Total amount was empty";
    } else if (terms_conditions === "no") {
      isErrorMsg = "Please accept terms & conditions";
    }

    if (isErrorMsg === "") {
      this.setState({ error_msg: "" });
      /* var postObject = {
        uniqueID: uniqueID,
        customer_id: this.state.UserId,
        plan_id: plan_id,
        plan_qty: plan_qty,
        subtotal_amount: subtotal_amount,
        total_amount: total_amount,
        tax_percentage: tax_percentage,
        terms_conditions: terms_conditions,
        promotions_updates: promotions_updates,
        qrcode: this.state.qrCode,
        checkoutType: this.state.checkoutType,
      }; */
    }
  }

  buyVoucher() {
    var productID = "";
    var productName = '';
    var unquie_IDs = uniqueID;
    if (this.state.checkoutType === "buyvoucher") {
      let productList = this.state.productList[0];
      productID = productList._id;
      unquie_IDs = productList.productCompanyUniqueID;
      productName = productList.productAliasName!==''?productList.productAliasName:productList.productName;
    } else {
      productID = this.state.plan_data.topupplan_id;
    }
    let customer_id = this.state.UserId;
    let plan_qty = this.state.plan_qty;
    let subtotal_amount = this.state.plan_subtotal;
    let total_amount = this.state.plan_grandtotal;
    let tax_percentage = this.state.tax_percentage;
    let terms_conditions = this.state.terms_conditions;
    let promotions_updates = this.state.promotions_updates;

    let isErrorMsg = "";
    if (customer_id === "") {
      isErrorMsg = "Customer Id was empty";
    } else if (productID === "") {
      isErrorMsg = "Product Id was empty";
    } else if (plan_qty === 0 || plan_qty === "") {
      isErrorMsg = "Plan Quantity was empty";
    } else if (subtotal_amount === 0 || subtotal_amount === "") {
      isErrorMsg = "Subtotal amount was empty";
    } else if (total_amount === 0 || total_amount === "") {
      isErrorMsg = "Total amount was empty";
    } else if (terms_conditions === "no") {
      isErrorMsg = "Please accept terms & conditions";
    }

    let influencerID = localStorage.getItem("influencerID") || "";

    if (isErrorMsg === "") {
      showLoader("placeOredr");
      this.setState({ error_msg: "" });
      var postObject = {
        uniqueID: unquie_IDs,
        customer_id: this.state.UserId,
        productQty: plan_qty,
        subtotal_amount: subtotal_amount,
        total_amount: total_amount,
        tax_percentage: tax_percentage,
        gstAmount: this.state.plan_gst_amount,
        terms_conditions: terms_conditions,
        promotions_updates: promotions_updates,
        qrcode: this.state.qrCode,
        checkoutType: this.state.checkoutType,
        influencerID: influencerID,
        influencerBuyNow: localStorage.getItem("influencerBuyNow") || "",
        shareProductID: localStorage.getItem("shareProductID") || "",
        analyticsType: localStorage.getItem("analyticsType") || "",
        callbackurl: baseUrl,
        savecard: this.state.savecard === true ? "Y" : "N",
        paymentMethod: this.state.paymentMethod,
        productID : productID,
        productName:productName,
        outletID:cookie.load("outletID") || ''
      };
    
      /* if (this.state.checkoutType === "buyvoucher") {
        postObject["productID"] = productID;
      } else {
        postObject["plan_id"] = productID;
      } */
      if (this.state.paymentMethod === "QR Payment") {
        axios
          .post(
            apiUrl + "wallettopup/topup",
            qs.stringify(postObject),
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status === "ok") {
              localStorage.removeItem("planData");
              Swal.fire({
                title: "Success",
                html: "Topup successful",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary waves-effect waves-light",
                },
                buttonsStyling: false,
              });

              this.props.history.push("/myaccount");
            } else {
              Swal.fire({
                title: "Error",
                html: res.data.message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary waves-effect waves-light",
                },
                buttonsStyling: false,
              });
              this.props.history.push("/refpage/checkout");
            }
          }).catch((e) => {
            console.error("Error fetching data:", e);
          });
      } else {
        window.sessionStorage.setItem("postObject", qs.stringify(postObject));
        if (this.state.paymentMethod === "MSwipe") {
          var post_Object = {
            uniqueID: uniqueID,
            qr_type: this.state.checkoutType,
            customer_id: this.state.UserId,
            payment_type: this.state.checkoutType,
            callbackurl: baseUrl + "placeorder",
            paid_amount: total_amount,
          };

          axios
            .post(
              apiUrl + "paymentV2/createPaymentLink",
              qs.stringify(post_Object),
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res) => {
              hideLoader("placeOredr");
              if (res.data.status === "ok") {
                var productList = this.state.productList[0];
                localStorage.setItem(
                  "paymentRequestId",
                  res.data.payment_requestId
                );
                localStorage.setItem(
                  "voucherType",
                  productList.productVoucherType
                );
                setTimeout(function () {
                  window.location.href = res.data.redirectURL;
                }, 500);
              }
            }).catch((e) => {
              console.error("Error fetching data:", e);
            });
        } else {
          axios
            .post(
              apiUrl + "payment/processReddotPayment",
              qs.stringify(postObject),
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res) => {
              hideLoader("placeOredr");
              if (res.data.status === "ok") {
                var productList = this.state.productList[0];
                localStorage.setItem(
                  "paymentRequestIdRedtod",
                  res.data.payment_requestId
                );
                localStorage.setItem(
                  "voucherType",
                  productList.productVoucherType
                );
                window.location.href = res.data.redirectURL;
              } else {
                showAlert("Alert", res.data.message, "error");
              }
            }).catch((e) => {
              console.error("Error fetching data:", e);
            });
        }
      }
    }else {
      this.setState({error_msg:isErrorMsg})
    }
  }
  selectPaymentMethod(paymentMethod) {
    var outletID = cookie.load("outletID") || "";
    this.setState({ temppaymentMethod: paymentMethod });
    if(outletID==="") {
      Swal.fire({
        title: "Error",
        html: "Please select any one outlet",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary waves-effect waves-light",
        },
        buttonsStyling: false,
      });
      return false;
    }
    this.setState({ paymentMethod, runingNum: 0 }, () => {
      clearInterval(downloadTimer);
      if (paymentMethod === "QR Payment") {
        if (this.state.checkoutType === "buyvoucher") {
          let productList = this.state.productList[0];
          this.generateCustQrcode(
            productList._id,
            productList.productCompanyUniqueID
          );
        } else {
          this.generateCustQrcode(this.state.plan_data.topupplan_id);
        }
        $("html, body").animate(
          {
            scrollTop: $(".checkout-qr").offset().top,
          },
          2000
        );
      }
    });
  }
  handleFldChange(e) {
    let name = e.target.name;
    this.setState({ [name]: e.target.value }, () => {
      if (name === "outletID") {
        cookie.save("outletID", e.target.value, { path: "/" });
        setTimeout(() =>  {
          if(this.state.temppaymentMethod!=="") {
            this.selectPaymentMethod(this.state.temppaymentMethod);
          }
        }, 500);
      }
    });
  }
  render() {
    let planData = this.state.plan_data;
    let planGrandtotal = this.state.plan_grandtotal;
    let planGstAmount = this.state.plan_gst_amount;
    let planSubtotal = this.state.plan_subtotal;
    if (Object.keys(planData).length > 0 || this.state.productList.length > 0) {
      let showSaveCard = '';
      if(this.state.paymentMethod === "mv3ds") {
        showSaveCard = this.state.customerData?.showSaveMVCard;
      } else if(this.state.paymentMethod === "amex3ds") {
        showSaveCard = this.state.customerData?.showSaveAmxCard;
      }

      return (
        <div className="directpay-new main-div">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />

          <div className="mbtm-need rel">
            <div className="container">
              <div className="cart-detail">
                <div className="cart-detail-header">
                  <h3>Your cart details</h3>
                  <a href={() => false} onClick={this.rvmQtyAction.bind(this)}>
                    Clear cart
                  </a>
                </div>
                <div className="cart-detail-body">
                  <div className="cart-detail-voucher">
                    <div className="cart-detail-voucher-img">
                      <figure>
                        <img src={voucyellow} alt="voucyellow" />
                      </figure>{" "}
                      <span>
                        {this.state.checkoutType === "buyvoucher"
                          ? this.state.productName
                          : planData.topupplan_display_name}
                      </span>
                    </div>
                    <div className="cart-detail-voucher-desc">
                      <div className="qty-bx">
                        <span
                          className="qty-minus"
                          onClick={this.plnQtyAction.bind(this, "decr")}
                        >
                          <img src={minus} alt="minus" />
                        </span>
                        <div className="input-quantity">
                          {this.state.plan_qty}
                        </div>
                        <span
                          className="qty-plus"
                          onClick={this.plnQtyAction.bind(this, "incr")}
                        >
                          <img src={plus} alt="plus" />
                        </span>
                      </div>
                      <div className="cart-price">
                        ${planSubtotal.toFixed(2)}
                      </div>
                      <div
                        className="cart-action"
                        onClick={this.rvmQtyAction.bind(this)}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span>Sub Total</span>
                      <strong>
                        <sup>$</sup>
                        {planSubtotal.toFixed(2)}
                      </strong>
                    </li>
                    {parseFloat(planGstAmount) > 0 && (
                      <li>
                        <span>GST</span>
                        <strong>
                          <sup>$</sup>
                          {planGstAmount.toFixed(2)}
                        </strong>
                      </li>
                    )}
                    <li className="ts-total">
                      <span>Total</span>
                      <strong>
                        <sup>$</sup>
                        {planGrandtotal.toFixed(2)}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card-chkinfo">
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      onClick={this.checkboxInfoFld.bind(this, "terms")}
                    />
                    <p>
                      I confirm I have read and accept the{" "}
                      <a href="/terms-and-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a>,{" "}
                      <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and the{" "}
                      <a href="/pdpa" target="_blank" rel="noreferrer">PDPA consent</a>.
                    </p>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      onClick={this.checkboxInfoFld.bind(this, "promotions")}
                    />
                    <p>
                      I'd like to receive news, updates and promotions from
                      Epicpay via email, whatsapp and sms
                    </p>
                  </li>
                  {(this.state.paymentMethod === "mv3ds" || this.state.paymentMethod === "amex3ds") && showSaveCard === "Yes" && (
                      <li>
                        <input
                          type="checkbox"
                          onClick={this.checkboxInfoFld.bind(this, "savecard")}
                        />
                        <p>Save Your Card details for feature transactions</p>
                      </li>
                    )}
                </ul>
              </div>

              <div className="payment-sec">
              <div className="payment-method">
              {this.state.outletList !== "" && (
                  <>
                  <h3>Outlet</h3>
                  <select
                    name="outletID"
                    value={this.state.outletID}
                    className="components_selct components_selctbox_cls"
                    onChange={this.handleFldChange.bind(this)}
                  >
                    {Parser(this.state.outletList)}
                  </select>
                  </>
                )}
                <h3>Payment Method</h3>
                {this.state.manualPayment === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "QR Payment"
                        ? "selected"
                        : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "QR Payment")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "QR Payment"
                            ? true
                            : false
                        }
                      />
                      QR Payment
                    </span>
                    <div>
                      <img
                        src={qrcodepay}
                        alt="QR Code Pay"
                        className="qr-code-pay"
                      />
                    </div>
                  </div>
                )}

                {this.state.reddotmv3ds === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "mv3ds" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "mv3ds")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "mv3ds" ? true : false
                        }
                      />
                      Master / Visa Card
                    </span>
                    <div>
                      <img
                        src={mastercard}
                        alt="MasterCard"
                        className="master-card"
                      />
                      <img src={visa} alt="Visa" className="visa-card" />
                    </div>
                  </div>
                )}
                {this.state.reddotapplepay === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "applepay" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "applepay")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "applepay" ? true : false
                        }
                      />
                      Apple Pay
                    </span>
                    <img src={applePay} alt="ApplePay" className="apple-icon" />
                  </div>
                )}
                {this.state.reddotamex3ds === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "amex3ds" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "amex3ds")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "amex3ds" ? true : false
                        }
                      />
                      Amex Pay
                    </span>
                    <img
                      src={amexPay}
                      alt="ApplePay"
                      className="amexpay-icon"
                    />
                  </div>
                )}

                {this.state.reddotgooglepay === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "googlepay" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "googlepay")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "googlepay"
                            ? true
                            : false
                        }
                      />
                      Google Pay
                    </span>
                    <img
                      src={googlePay}
                      alt="ApplePay"
                      className="googlepay-icon"
                    />
                  </div>
                )}

                {this.state.reddotapplepay === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "atome" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "atome")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "atome" ? true : false
                        }
                      />
                      Atome Pay
                    </span>
                    <img src={atome} alt="ApplePay" className="atome-icon" />
                  </div>
                )}

                {this.state.reddotpaynow === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "paynow" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "paynow")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "paynow" ? true : false
                        }
                      />
                      PayNow
                    </span>
                    <img src={paynow} alt="PayNow" className="paynow-icon" />
                  </div>
                )}

                {this.state.reddotgrabpay === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "grabpay" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "grabpay")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "grabpay" ? true : false
                        }
                      />
                      GrabPay
                    </span>
                    <img src={grabpay} alt="GrabPay" className="grabpay-icon" />
                  </div>
                )}

                {this.state.mswipePayment === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "MSwipe" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "MSwipe")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "MSwipe" ? true : false
                        }
                      />
                      MSwipe
                    </span>
                    <img src={mswipe} alt="ApplePay" className="mswipe-icon" />
                  </div>
                )}
              </div>
              </div>
              
              {this.state.paymentMethod === "QR Payment" && (
                <div className="card-detail">
                  <div className="cart-detail-header">
                    <h3>Scan QR Code and get amount</h3>
                  </div>
                  <div className="card-detail-body checkout-qr">
                    {this.state.runingNum > 0 ? (
                      <img src={this.state.qrcode_str} alt="QR Code" />
                    ) : (
                      <img
                        src={reloadQr}
                        onClick={this.reloadQrFun.bind(this)}
                        alt="QR Code"
                      />
                    )}

                    {this.state.runingNum > 0 && (
                      <div className="vod-footer">
                        <span>QR Code expire in</span>
                        <h2>{this.state.runingNum}</h2>
                        <p>Seconds</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              </div>
           
          </div>
          {this.state.paymentMethod !== "QR Payment" && (
            <IonFooter collapse="fade">
              <div className="sticky-single-btn">
                <a
                  href={() => false}
                  className="button green-btn place-order-link"
                  id="placeOredr"
                  onClick={this.proceedPayment.bind(this)}
                >
                  Pay with{" "}
                  <span>
                  {(() => {
                      if (this.state.paymentMethod === "mv3ds") {
                        return (
                          <>
                            <img
                              src={mastercard}
                              alt="Master"
                              className="master-card"
                            />
                            <img src={visa} alt="Visa" className="visa-card" />
                          </>
                        );
                      } else if (this.state.paymentMethod === "applepay") {
                        return (
                          <img
                            src={applePay}
                            alt="ApplePay"
                            className="apple-icon"
                          />
                        );
                      } else if (this.state.paymentMethod === "amex3ds") {
                        return (
                          <img
                            src={amexPay}
                            alt="ApplePay"
                            className="amexpay-icon"
                          />
                        );
                      } else if (this.state.paymentMethod === "googlepay") {
                        return (
                          <img
                            src={googlePay}
                            alt="Google Pay"
                            className="googlepay-icon"
                          />
                        );
                      } else if (this.state.paymentMethod === "atome") {
                        return (
                          <img
                            src={atome}
                            alt="Atome Pay"
                            className="atome-icon"
                          />
                        );
                      } else if (this.state.paymentMethod === "paynow") {
                        return (
                          <img
                            src={paynow}
                            alt="Pay Now"
                            className="paynow-icon"
                          />
                        );
                      } else if (this.state.paymentMethod === "grabpay") {
                        return (
                          <img
                            src={GrabPaywhite}
                            alt="Grab Pay"
                            className="grabpay-icon"
                          />
                        );
                      } else if (this.state.paymentMethod === "MSwipe") {
                        return (
                          <img
                            src={mswipe}
                            alt="MSwipe"
                            className="mswipe-icon"
                          />
                        );
                      }
                    })()}
                    <label>Pay</label>
                  </span>
                </a>
                <div>
                  <span className="error">{this.state.error_msg}</span>
                </div>
              </div>
            </IonFooter>
          )}
        </div>
      );
    } else {
      return (
        <div className="container">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div id="loading-indicator">
            <div class="lds-hourglass"></div>
          </div>
        </div>
      );
    }
  }
}

const mapStateTopProps = (state) => {
  var customerdetailsArr = [];
  if (Object.keys(state.customerdetails).length > 0) {
    if (state.customerdetails[0].status === "ok") {
      customerdetailsArr = state.customerdetails[0].result;
    }
  }
  return {
    customerdetails: customerdetailsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAILS, params });
    }
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Checkout));
