import React, { Component } from "react";
import $ from "jquery";
import cookie from "react-cookies";
import update from "immutability-helper";
import axios from "axios";
import { apiUrl, uniqueID, baseUrl } from "../Settings/Config";
import { showLoaderLst, hideLoaderLst } from "../Helpers/SettingHelper";

import cellphone1 from "../../common/images/cellphone1.svg";
var qs = require("qs");
class Login extends Component {
  constructor(props) {
    super(props);
   
    /* var refcode =
      localStorage.getItem("refcode") === null
        ? ""
        : localStorage.getItem("refcode"); */
    var triggerOTP = "No";
    var triggerFrom = "";
    var bottompopup = "regphone";
    var reg_phone_no = "";
    if (cookie.load("triggerOTP") === "Yes") {
      triggerOTP = "Yes";
      triggerFrom = cookie.load("triggerFrom");
      reg_phone_no = cookie.load("UserMobile");
      bottompopup = "regotp";
    }

    this.state = {
      runingNum: 0,
      bottompopup: bottompopup,
      trigger_otp: triggerOTP,
      trigger_from: triggerFrom,
      reg_phone_no: reg_phone_no,
      regphone_error: "",
      regotpdata: {
        reg_otp_dgt1: "",
        reg_otp_dgt2: "",
        reg_otp_dgt3: "",
        reg_otp_dgt4: "",
      },
      regotp_terms: "No",
      regotp_promo: "No",
      regotp_error: "",
      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      regcust_error: "",
      openLogin: false,
    };
  }

  handleFldChange(event) {
    const re = /^[0-9 \b]+$/;
    if (event.target.name === "reg_phone_no") {
      if (event.target.value === "" || re.test(event.target.value)) {
        var mblnumber = this.space(event.target.value);
        var mblnumberLenght = mblnumber.length;
        if (mblnumberLenght <= 9) {
          this.setState({ reg_phone_no: mblnumber });
        }
      }
    } else if (
      event.target.name === "reg_otp_dgt1" ||
      event.target.name === "reg_otp_dgt2" ||
      event.target.name === "reg_otp_dgt3" ||
      event.target.name === "reg_otp_dgt4"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        var mblnumber_ = this.space(event.target.value);
        var mblnumberLenght_ = mblnumber_.length;
        if (mblnumberLenght_ <= 1) {
          let field = event.target.name;
          let fieldvalue = event.target.value;
          this.setState(
            update(this.state, {
              regotpdata: { [field]: { $set: fieldvalue } },
            }),
            function () {
              if (field === "reg_otp_dgt1" && mblnumberLenght_ === 1) {
                $("#reg_otp_dgt2").focus();
              }
              if (field === "reg_otp_dgt2" && mblnumberLenght_ === 1) {
                $("#reg_otp_dgt3").focus();
              }
              if (field === "reg_otp_dgt3" && mblnumberLenght_ === 1) {
                $("#reg_otp_dgt4").focus();
              }
            }
          );
        }
      }
    } else if (event.target.name === "cust_name") {
      this.setState({ cust_name: event.target.value });
    } else if (event.target.name === "cust_email_id") {
      this.setState({ cust_email_id: event.target.value });
    } else if (event.target.name === "birthday_month") {
      this.setState({ birthday_month: event.target.value });
    } else if (event.target.name === "cust_ref_code") {
      this.setState({ cust_ref_code: event.target.value });
    }
    this.setState({ regphone_error: "", regotp_error: "", regcust_error: "" });
  }

  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }

  setRuningNum() {
    var rct_this = this;
    var runingNum = this.state.runingNum;
    var downloadTimer = setInterval(function () {
      if (runingNum <= 0) {
        clearInterval(downloadTimer);
      }
      runingNum = runingNum - 1;
      rct_this.setState({ runingNum: runingNum });
    }, 1000);
  }

  triggerOTPFunction() {
    var regphoneNo = cookie.load("UserMobile") ?? '';;
    if (regphoneNo !== "") {
      console.log(regphoneNo, "regphoneNo");
      regphoneNo = regphoneNo.replace(/ /g, "");
      var postObject = {
        uniqueID: uniqueID,
        customer_mobile: regphoneNo,
      };
      showLoaderLst("home-page-maindiv", "class");
      axios
        .post(apiUrl + "customer/send_otponly", postObject)
        .then((res) => {
          hideLoaderLst("home-page-maindiv", "class");
          if (res.data.status === "ok") {
            this.setState(
              { bottompopup: "regotp", runingNum: 120 },
              function () {
                this.setRuningNum();
              }.bind(this)
            );
          } else {
            this.setState({ regphone_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regphone_error: "Phone number required" });
    }
  }

  regSendOtp(typeflg, event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    if (regphoneNo !== "") {
      var postObject = {
        customerPhone: regphoneNo,
      };
      if (uniqueID !== "") {
        postObject["uniqueID"] = uniqueID;
      }
      let loadrdivcls = typeflg === "login" ? "login-otp-link" : "reg-otp-link";
      showLoaderLst(loadrdivcls, "class");
      axios
        .post(apiUrl + "customer/generateOTP", postObject)
        .then((res) => {
          hideLoaderLst(loadrdivcls, "class");
          if (res.data.status === "ok") {
            if (
              typeflg === "login" ||
              (typeflg === "register" && res.data.is_existing_user === "yes")
            ) {
              let custArr = res.data.result;
              cookie.save("UserId", custArr.customer_id, { path: "/" });
              cookie.save("UserEmail", custArr.customer_email, { path: "/" });
              cookie.save(
                "UserFname",
                custArr.customer_first_name !== ""
                  ? custArr.customer_first_name
                  : "",
                { path: "/" }
              );
              cookie.save(
                "UserLname",
                custArr.customer_last_name !== ""
                  ? custArr.customer_last_name
                  : "",
                { path: "/" }
              );
              cookie.save("UserMobile", regphoneNo, { path: "/" });
              cookie.save("IsVerifiedUser", "No", { path: "/" });
              localStorage.setItem("token", custArr.token);
              cookie.remove("loginType", { path: "/" });
              if (uniqueID !== "") {
                localStorage.setItem("allowCompanyRedirect", "Yes");
              }
              let $_this = this;
              if (this.props.location?.state) {
                localStorage.setItem(
                  "voucherSlug",
                  $_this.props.location.state.voucherSlug
                );
                localStorage.setItem(
                  "voucherquantity",
                  $_this.props.location.state.quantity
                );
                setTimeout(() => {
                  this.props.sateValChange("redirect", baseUrl + "directpay");
                }, 500);
              } else {
                setTimeout(() => {
                  this.props.sateValChange("redirect", baseUrl + "directpay");
                }, 100);
              }
            } else {
              /*var bottompopuptxt = (typeflg == 'login')? 'loginotp' : 'regotp';*/
              this.setState({ bottompopup: "confirm" });
            }
          } else {
            this.setState({ regphone_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regphone_error: "Phone number required" });
    }
  }

  confirmPhone(event) {
    event.preventDefault();
    this.setState(
      { bottompopup: "regotp", runingNum: 120 },
      function () {
        this.setRuningNum();
      }.bind(this)
    );
  }

  displayRunNumbar() {
    if (this.state.regotp_error !== "") {
      return <p className="error_info">{this.state.regotp_error}</p>;
    } else if (this.state.runingNum > 0) {
      return (
        <p className="runing_num">Resend in {this.state.runingNum} Seconds</p>
      );
    } else {
      return <p className="resend_link">Resend</p>;
    }
  }

  chakRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    if (field === "trams") {
      var chkBox = regotp_terms == "Yes" ? true : false;
      return chkBox;
    } else if (field === "promo") {
      var chkBox = regotp_promo == "Yes" ? true : false;
      return chkBox;
    }
  }

  changeRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    if (field === "trams") {
      var regotpterms = regotp_terms == "Yes" ? "No" : "Yes";
      this.setState({ regotp_terms: regotpterms });
      if (regotp_terms === "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }

    if (field === "promo") {
      var regotpPromo = regotp_promo == "Yes" ? "No" : "Yes";
      this.setState({ regotp_promo: regotpPromo });
      if (regotpPromo === "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }
  }

  cancelAct(event) {
    event.preventDefault();
    var triggerfrom = this.state.trigger_from;
    triggerfrom =
      triggerfrom != "" && triggerfrom != undefined ? triggerfrom : "myaccount";
    if (triggerfrom == "checkout") {
      triggerfrom = "myaccount";
    }
    this.props.history.push("/" + triggerfrom);
  }

  tgrVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    console.log(regphoneNo, "regphoneNo");
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length != 4) {
      errorMgs = "Please enter the OTP";
    }

    if (regphoneNo != "" && errorMgs == "") {
      var postObject = {
        uniqueID: uniqueID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "redeempoints",
        influencerID: localStorage.getItem("influencerID"),
      };
      showLoaderLst("reg-otp-verify", "class");
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          hideLoaderLst("reg-otp-verify", "class");
          if (res.data.status === "ok") {
            cookie.save("IsVerifiedUser", "Yes", { path: "/" });
            var triggerfrom = this.state.trigger_from;
            triggerfrom =
              triggerfrom != "" && triggerfrom != undefined
                ? triggerfrom
                : "myaccount";
            let $_this = this;
            console.log(this.props, "this.props");
            if (this.props.location?.state) {
              setTimeout(function () {
                $_this.props.history.push({
                  pathname: $_this.props.location.state.triggerFrom,
                  state: $_this.props.location.state,
                });
              }, 500);
            } else {
              setTimeout(function () {
                $_this.props.history.push("/" + triggerfrom);
              }, 500);
            }
            return false;
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }

  regVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length != 4) {
      errorMgs = "Please enter the OTP";
    } else if (this.state.regotp_terms != "Yes") {
      errorMgs = "Terms & Conditions required";
    } else if (this.state.regotp_promo != "Yes") {
      errorMgs = "Promotions required";
    }

    if (regphoneNo != "" && errorMgs == "") {
      var postObject = {
        uniqueID: uniqueID,
        customerPhone: regphoneNo,
        customerOTP: regotpval,
        otp_type: "register",
      };
      showLoaderLst("reg-otp-verify", "class");
      axios
        .post(
          apiUrl + "customer/verifyOTP",
          qs.stringify(postObject)
        )
        .then((res) => {
          hideLoaderLst("reg-otp-verify", "class");
          if (res.data.status === "ok") {
            this.setState({ bottompopup: "regpersonal", runingNum: 0 });
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }

  goBackTo(refPage, event) {
    event.preventDefault();
    var regotpdata = {
      reg_otp_dgt1: "",
      reg_otp_dgt2: "",
      reg_otp_dgt3: "",
      reg_otp_dgt4: "",
    };
    this.setState({
      bottompopup: refPage,
      reg_phone_no: "",
      regphone_error: "",
      regotpdata: regotpdata,
      regotp_terms: "No",
      regotp_promo: "No",
      regotp_error: "",
      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      cust_ref_code: "",
      regcust_error: "",
    });
  }

  custRegistration(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var errorMgs = "";
    if (regphoneNo == "") {
      errorMgs = "Mobile No required";
    } else if (this.state.cust_name == "") {
      errorMgs = "Name required";
    } else if (this.state.cust_email_id == "") {
      errorMgs = "Email required";
    } else if (this.state.birthday_month == "") {
      errorMgs = "Birthday month required";
    }

    if (errorMgs == "") {
      var shareProductID =
        cookie.load("shareProductID") !== "" &&
        typeof cookie.load("shareProductID") !== undefined &&
        typeof cookie.load("shareProductID") !== "undefined"
          ? cookie.load("shareProductID")
          : "";
      var analyticsType =
        cookie.load("analyticsType") !== "" &&
        typeof cookie.load("analyticsType") !== undefined &&
        typeof cookie.load("analyticsType") !== "undefined"
          ? cookie.load("analyticsType")
          : "";
      var postObject = {
        uniqueID: uniqueID,
        customerPhone: regphoneNo,
        customerEmail: this.state.cust_email_id,
        customerBirthMonth: this.state.birthday_month,
        customerReferralCode: this.state.cust_ref_code,
        customerName: this.state.cust_name,
        shareProductID: localStorage.getItem("shareProductID") || "",
        analyticsType: localStorage.getItem("analyticsType") || "",
      };
      showLoaderLst("cust-reg-btn", "class");
      axios
        .post(apiUrl + "customer/registration", postObject)
        .then((res) => {
          hideLoaderLst("cust-reg-btn", "class");
          if (res.data.status === "ok") {
            //this.setState({ bottompopup: 'regpersonal', runingNum: 0 });

            let custArr = res.data.result_set;
            cookie.save("UserId", custArr.customer_id, { path: "/" });
            cookie.save("UserEmail", custArr.customer_email, { path: "/" });
            cookie.save(
              "UserFname",
              custArr.customer_first_name !== ""
                ? custArr.customer_first_name
                : "",
              { path: "/" }
            );
            cookie.save(
              "UserLname",
              custArr.customer_last_name !== ""
                ? custArr.customer_last_name
                : "",
              { path: "/" }
            );
            cookie.save("UserMobile", regphoneNo, { path: "/" });
            cookie.save("IsVerifiedUser", "Yes", { path: "/" });
            localStorage.setItem("token", custArr?.token);
            cookie.remove("loginType", { path: "/" });
            setTimeout(() => {
              this.props.sateValChange("redirect", baseUrl + "directpay");
            }, 100);
          } else {
            this.setState({ regcust_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regcust_error: errorMgs });
    }
  }

  loginVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length != 4) {
      errorMgs = "Please enter the OTP";
    }

    if (regphoneNo != "" && errorMgs == "") {
      var postObject = {
        uniqueID: uniqueID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "login",
      };
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({ runingNum: 0 });
            this.props.history.push("myaccount");
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }
  openLogin(e) {
    e.preventDefault();
    this.setState({ openLogin: true });
  }

  render() {
    return (
      <div className="container">
        {this.state.bottompopup == "regphone" && (
          <div className="welcome-phone">
            <div className="wp-top">
              <h2>Welcome to EpicPay</h2>
              <p>Enter your phone number to create your account</p>
            </div>
            <div className="input-phone">
              <div className="prefix-merge">
                <div className="prefix">+65</div>
                <div className="nxt-fix">
                  <input
                    type="input"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    name="reg_phone_no"
                    value={this.state.reg_phone_no}
                    placeholder="Phone number"
                    onChange={this.handleFldChange.bind(this)}
                  />
                </div>
              </div>
              {this.state.regphone_error != "" && (
                <span className="error-info">{this.state.regphone_error}</span>
              )}
              <p>
                We will send you a one-time SMS, Additional carriers charges may
                apply
              </p>
            </div>
            <div className="bottom-btn bb-txt textcenter">
              <a
                href={void 0}
                className="button full-btn reg-otp-link"
                onClick={this.regSendOtp.bind(this, "register")}
              >
                Send OTP
              </a>
              <p>
                Do you have an account ?{" "}
                <a href={void 0} onClick={this.goBackTo.bind(this, "loginfrm")}>
                  Login here
                </a>
              </p>
            </div>
          </div>
        )}

        {this.state.bottompopup == "confirm" && (
          <div className="update-personal">
            <div className="up-header confirm-header textcenter">
              <h2>
                You have entered<br></br>+65 {this.state.reg_phone_no}
              </h2>
              <img src={cellphone1} />
            </div>
            <div className="up-form confirm-phone">
              <p>
                A one-time verification code will be sent to this mobile number
              </p>
              <p>Press ‘Confirm’ to proceed or ‘Edit’ to amend your details.</p>
              {this.state.regcust_error != "" && (
                <p className="error_info">{this.state.regcust_error}</p>
              )}
              <div className="up-frm-btn">
                <a
                  href={void 0}
                  className="button ghost-btn"
                  onClick={this.goBackTo.bind(this, "regphone")}
                >
                  Edit
                </a>
                <a
                  href={void 0}
                  className="button cust-reg-btn"
                  onClick={this.confirmPhone.bind(this)}
                >
                  Confirm
                </a>
              </div>
            </div>
          </div>
        )}

        {this.state.bottompopup == "regotp" && (
          <div className="enter-otp">
            <h2>Enter OTP</h2>
            <div className="four-bx-col">
              <div className="four-bx">
                <input
                  type="input"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  name="reg_otp_dgt1"
                  id="reg_otp_dgt1"
                  value={this.state.regotpdata.reg_otp_dgt1}
                  onChange={this.handleFldChange.bind(this)}
                />
                <input
                  type="input"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  name="reg_otp_dgt2"
                  id="reg_otp_dgt2"
                  value={this.state.regotpdata.reg_otp_dgt2}
                  onChange={this.handleFldChange.bind(this)}
                />
                <input
                  type="input"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  name="reg_otp_dgt3"
                  id="reg_otp_dgt3"
                  value={this.state.regotpdata.reg_otp_dgt3}
                  onChange={this.handleFldChange.bind(this)}
                />
                <input
                  type="input"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  name="reg_otp_dgt4"
                  id="reg_otp_dgt4"
                  value={this.state.regotpdata.reg_otp_dgt4}
                  onChange={this.handleFldChange.bind(this)}
                />
              </div>
              {this.displayRunNumbar()}
            </div>
            {this.state.trigger_otp != "Yes" && (
              <div className="otp-checkbox">
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      name="regotp_terms"
                      onChange={this.changeRegOtpChkBox.bind(this, "trams")}
                      checked={this.chakRegOtpChkBox("trams")}
                    />
                    <p>
                      I confirm I have read and accept the{" "}
                      <a href="/terms-and-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a>,{" "}
                      <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and the{" "}
                      <a href="/pdpa" target="_blank" rel="noreferrer">PDPA consent</a>.
                    </p>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      name="regotp_promo"
                      onChange={this.changeRegOtpChkBox.bind(this, "promo")}
                      checked={this.chakRegOtpChkBox("promo")}
                    />
                    <p>
                      I'd like to receive news, updates and promotions from
                      EpicPay via email, whatsapp and sms
                    </p>
                  </li>
                </ul>
              </div>
            )}
            {this.state.trigger_otp === "Yes" ? (
              <div className="up-frm-btn">
                <a
                  href={void 0}
                  className="button ghost-btn"
                  onClick={this.cancelAct.bind(this)}
                >
                  Cancel
                </a>
                <a
                  href={void 0}
                  className="button cust-reg-btn"
                  onClick={this.tgrVerifyOtp.bind(this)}
                >
                  Continue
                </a>
              </div>
            ) : (
              <div className="bottom-btn textcenter">
                <a
                  href={void 0}
                  className="button full-btn reg-otp-verify"
                  onClick={this.regVerifyOtp.bind(this)}
                >
                  Continue
                </a>
              </div>
            )}
          </div>
        )}

        {this.state.bottompopup == "regpersonal" && (
          <div className="update-personal">
            <div className="up-header textcenter">
              <p>Update your personal details</p>
            </div>
            <div className="up-form">
              <input
                type="text"
                placeholder="Name"
                name="cust_name"
                value={this.state.cust_name}
                onChange={this.handleFldChange.bind(this)}
              />
              <input
                type="text"
                placeholder="Email Address"
                name="cust_email_id"
                value={this.state.cust_email_id}
                onChange={this.handleFldChange.bind(this)}
              />
              <select
                name="birthday_month"
                value={this.state.birthday_month}
                className="components_selct components_selctbox_cls"
                onChange={this.handleFldChange.bind(this)}
              >
                <option value="">Birthday Month</option>
                <option value="january">January</option>
                <option value="february">February</option>
                <option value="march">March</option>
                <option value="april">April</option>
                <option value="may">May</option>
                <option value="june">June</option>
                <option value="july">July</option>
                <option value="august">August</option>
                <option value="september">September</option>
                <option value="october">October</option>
                <option value="november">November</option>
                <option value="december">December</option>
              </select>
              <input
                type="text"
                placeholder="Referral Code"
                name="cust_ref_code"
                value={this.state.cust_ref_code}
                onChange={this.handleFldChange.bind(this)}
              />
              {this.state.regcust_error != "" && (
                <p className="error_info">{this.state.regcust_error}</p>
              )}
              <div className="up-frm-btn">
                <a
                  href={void 0}
                  className="button ghost-btn"
                  onClick={this.goBackTo.bind(this, "regphone")}
                >
                  Cancel
                </a>
                <a
                  href={void 0}
                  className="button cust-reg-btn"
                  onClick={this.custRegistration.bind(this)}
                >
                  Register
                </a>
              </div>
            </div>
          </div>
        )}

        {this.state.bottompopup == "loginfrm" && (
          <div className="welcome-phone login-frm">
            <div className="wp-top">
              <h2>Login to EpicPay</h2>
              <p>Enter your phone number to and login to your account</p>
            </div>
            <div className="input-phone">
              <div className="prefix-merge">
                <div className="prefix">+65</div>
                <div className="nxt-fix">
                  <input
                    type="input"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    name="reg_phone_no"
                    value={this.state.reg_phone_no}
                    placeholder="Phone number"
                    onChange={this.handleFldChange.bind(this)}
                  />
                </div>
              </div>
              {this.state.regphone_error != "" && (
                <span className="error-info">{this.state.regphone_error}</span>
              )}
              <p>
                We will send you a one-time SMS, Additional carriers charges may
                apply
              </p>
            </div>
            <div className="bottom-btn bb-txt textcenter">
              <a
                href={void 0}
                className="button full-btn login-otp-link"
                onClick={this.regSendOtp.bind(this, "login")}
              >
                Login
              </a>
              <p>
                Do not have an account ?{" "}
                <a href={void 0} onClick={this.goBackTo.bind(this, "regphone")}>
                  Register here
                </a>
              </p>
            </div>
          </div>
        )}

        {this.state.bottompopup == "loginotp" && (
          <div className="enter-otp enter-otp-login">
            <h2>Enter OTP</h2>
            <div className="four-bx-col">
              <div className="four-bx">
                <input
                  type="input"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  name="reg_otp_dgt1"
                  id="reg_otp_dgt1"
                  value={this.state.regotpdata.reg_otp_dgt1}
                  onChange={this.handleFldChange.bind(this)}
                />
                <input
                  type="input"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  name="reg_otp_dgt2"
                  id="reg_otp_dgt2"
                  value={this.state.regotpdata.reg_otp_dgt2}
                  onChange={this.handleFldChange.bind(this)}
                />
                <input
                  type="input"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  name="reg_otp_dgt3"
                  id="reg_otp_dgt3"
                  value={this.state.regotpdata.reg_otp_dgt3}
                  onChange={this.handleFldChange.bind(this)}
                />
                <input
                  type="input"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  name="reg_otp_dgt4"
                  id="reg_otp_dgt4"
                  value={this.state.regotpdata.reg_otp_dgt4}
                  onChange={this.handleFldChange.bind(this)}
                />
              </div>
              {this.displayRunNumbar()}
            </div>
            <div className="bottom-btn">
              <a
                href={void 0}
                className="button full-btn"
                onClick={this.loginVerifyOtp.bind(this)}
              >
                Login
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Login;
