/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../Layout/Header";
import cookie from "react-cookies";
import { BulletList } from "react-content-loader";
import Swal from "sweetalert2";
import {
  apiUrl,
  uniqueID,
  stripeReference,
} from "../Settings/Config";
import { showPriceValue } from "../Helpers/SettingHelper";
import { Tooltip as ReactTooltip } from "react-tooltip";
var base64 = require("base-64");
var qs = require("qs");
class History extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current_page: "My Subscription",

      subscriptionLoad: true,
      subscriptionList: [],
      dispalySubscription: "",
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }

    if (uniqueID == "") {
      props.history.push("/home");
    }
  }
  componentDidMount() {
    $("body").addClass("hide-overlay");
    this.loadSubscription();
  }

  loadSubscription() {
    if (
      uniqueID !== "" &&
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      axios
        .get(
          apiUrl +
            "subscription/list",
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          }
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState(
              {
                subscriptionList: res.data.result,
              },
              function () {
                this.dispalySubscription();
              }
            );
          } else {
            this.setState({ subscriptionLoad: false });
          }
        })
        .catch((error) => {
          this.setState({ subscriptionLoad: false }, () => {
            this.dispalySubscription();
          });
          console.error("Error fetching data:", error);
        });
    }
  }

  dispalySubscription() {
    var dispalySubscription = "";
    if (this.state.subscriptionList.length > 0) {
      dispalySubscription = this.state.subscriptionList.map((item, index) => {
        var subscribePeriod = "";
        if (item.subscribe_period === "W") {
          subscribePeriod = "Weekly";
        } else if (item.subscribe_period === "M") {
          subscribePeriod = "Monthly";
        } else if (item.subscribe_period === "Q") {
          subscribePeriod = "Quarterly";
        } else if (item.subscribe_period === "B") {
          subscribePeriod = "Biannually";
        } else if (item.subscribe_period === "A") {
          subscribePeriod = "Annually";
        }
        var subscribstatus = "";
        if (item.subscribe_status === "A") {
          subscribstatus = "Active";
        } else if (item.subscribe_status === "I") {
          subscribstatus = "Inactive";
        } else if (item.subscribe_status === "C") {
          subscribstatus = "Cancelled";
        } else if (item.subscribe_status === "P") {
          subscribstatus = "Pending";
        }
        var subscribe_vouchers =
          item.subscribe_vouchers !== "" && item.subscribe_vouchers !== null
            ? JSON.parse(item.subscribe_vouchers)
            : [];
        return (
          <li key={index}>
            <div className="hlm-lhs">
              <p>
                {" "}
                {item.subscribe_item_qty} X {subscribePeriod}{" "}
                {item.subscribe_item_name}{" "}
              </p>
              <b
                className={
                  item.subscribe_status === "C" ? "subscribe-cancel" : ""
                }
              >
                {subscribstatus}
              </b>
              <br />
              <span>
                Started: {item.subscribe_start_date} | Next:{" "}
                {item.subscribe_next_cycle}
              </span>
              <br />
              <Link
                to={"/subscription-history/" + base64.encode(item.subscribe_id)}
                className="subscribe-view-history"
              >
                View History
              </Link>{" "}
              {item.subscribe_status !== "C" && (
                <>
                  |{" "}
                  <a
                    href={void 0}
                    onClick={this.cancelSubscribe.bind(this, item)}
                    className="subscribe-view-history"
                  >
                    Cancel
                  </a>
                </>
              )}
            </div>
            <div className="hlm-rhs">
              <strong>
                {showPriceValue(item.subscribe_grandtotal)}{" "}
                <figure>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip
                    id={"happyFace" + index}
                  ></i>
                  <ReactTooltip
                    anchorId={"happyFace" + index}
                    type="success"
                    place="left"
                  >
                    {item.subscribe_item_type === "product" ? (
                      <b>Voucher Subscription</b>
                    ) : (
                      <b>Store Subscription</b>
                    )}
                    {subscribe_vouchers.length > 0 &&
                      subscribe_vouchers.map((sItem, sIndex) => {
                        return (
                          <div key={sIndex}>
                            {sItem.quantity} X {sItem.product_name}
                          </div>
                        );
                      })}
                  </ReactTooltip>
                </figure>
              </strong>
            </div>
          </li>
        );
      });
    } else {
      dispalySubscription = (
        <li>
          <div
            className="hlm-lhs"
            style={{ textAlign: "center", width: "100%" }}
          >
            <p>&nbsp;</p>
            <span>No History Of Subscription</span>
          </div>
        </li>
      );
    }
    this.setState({
      dispalySubscription: dispalySubscription,
      subscriptionLoad: false,
    });
  }

  cancelSubscribe(subscribeData) {
    var currentThis = this;
    var formPayload = {
      subscriptionID: base64.encode(subscribeData.subscribe_id),
      customer_id: cookie.load("UserId"),
      UniqueID: subscribeData.subscribe_company_unquie_id,
      payment_reference: stripeReference,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You want cancel this subscription!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, cancel it!",
      customClass: {
        confirmButton: "btn btn-primary me-3 waves-effect waves-light",
        cancelButton: "btn red-btn btn-label-secondary waves-effect",
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return axios
          .post(
            apiUrl + "subscription/cancelSubscription",
            qs.stringify(formPayload),
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          }).catch((e) => {
            console.error("Error fetching data:", e);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.value !== "" && typeof result.value !== undefined) {
        if (result.value.status == "ok") {
          currentThis.loadSubscription();
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: result.value.message,
            customClass: {
              confirmButton: "btn btn-success waves-effect",
            },
          });
        } else {
          Swal.fire({
            title: "Alert",
            text: result.value.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger waves-effect",
            },
          });
        }
      }
    });
  }

  render() {
    return (
      <div className="main-div trans-page-inner">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel">
          <div className="container">
            <div className="history-list">
              <div className="history-filter"></div>
              <div className="history-list-main">
                <br />
                {this.state.subscriptionLoad === true ? (
                  <BulletList
                    backgroundColor={"#c7c7c7"}
                    foregroundColor={"#c7c7c7"}
                    title="Fetching Your Subscriptions"
                  />
                ) : (
                  <ul>{this.state.dispalySubscription}</ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default History;
