/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_REWARDSETTING_LIST, SET_REWARDSETTING_LIST } from "../actions";
import { apiUrl, uniqueID } from "../components/Settings/Config";
import Axios from "axios";

export const watchGetRewardSettingList = function* () {
  yield takeEvery(GET_REWARDSETTING_LIST, workerGetRewardSettingList);
};

function* workerGetRewardSettingList(reqData) {
  try {
    const uri =
      apiUrl +
      "rewardsettings/list?uniqueID=" +
      uniqueID +
      "" +
      reqData.params;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_REWARDSETTING_LIST, value: resultArr });
  } catch (e) {
    let returnerror = [];
    returnerror.push(e?.response?.data || []);
    yield put({ type: SET_REWARDSETTING_LIST, value: returnerror });
  }
}
