import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import cookie from "react-cookies";
import { Instagram } from "react-content-loader";
import { format } from "date-fns";
import {
  GET_CUSTOMER_DETAILS,
  GET_PRODUCT_LIST,
  GET_PROMOTION_LIST,
} from "../../actions";
import { apiUrl, uniqueID, defaultLatitude, defaultLongitude } from "../Settings/Config";
import Header from "../Layout/Header";
import coin from "../../common/images/coin.svg";
import noImage from "../../common/images/no-image.jpg";
import gui from "../../common/images/guide.png";
import { showPriceValue, addressFormat, calculateDistance, calculateDays } from "../Helpers/SettingHelper";

var categorySetting = {
  autoWidth: true,
  nav: true,
  margin: 0,
  items: 4,
  slideBy: 4,
};
class Vouchers extends Component {
  constructor(props) {
    super(props);
    var vouchersShow = cookie.load("vouchers_show") || "all";
    if (this.props.location.pathname === "/vouchers/available") {
      vouchersShow = "available";
    }
    var vouchersFrom = cookie.load("vouchers_from") || "category";

    var selectedcompanyData =
      localStorage.getItem("selectedcompany_data") === null || uniqueID === ""
        ? ""
        : localStorage.getItem("selectedcompany_data");
    selectedcompanyData =
      selectedcompanyData !== "" && selectedcompanyData !== undefined
        ? JSON.parse(selectedcompanyData)
        : [];

    var catApiStatus =
      localStorage.getItem("catApiStatus") === null || uniqueID === ""
        ? ""
        : localStorage.getItem("catApiStatus");
    catApiStatus =
      catApiStatus !== "" && catApiStatus !== undefined
        ? JSON.parse(catApiStatus)
        : [];
    var voucherPageName = this.props.match.path;
    console.log(voucherPageName, 'voucherPageNamevoucherPageName')
    var voucherType = "points";
    var current_page = "Vouchers";
    var productType = "5";
    if (voucherPageName === "/buy-vouchers") {
      voucherType = "buyvoucher";
      current_page = "Buy Vouchers";
      productType = "7";
    }
    this.state = {
      catApiStatus: catApiStatus,
      urlPath: voucherPageName,
      voucherType: voucherType,
      current_page: current_page,
      actionfrom: vouchersFrom,
      activetab: vouchersShow,
      selectedcompany_data: selectedcompanyData,
      category_list: [],
      selected_cate_id:  (voucherPageName==='/vouchers')?'all':"companycat",
      catapi_status: [],
      proapi_status: "inital",
      faildProduct:'',
      productList: [],
      promotionlist: [],
      available_promo_count: 0,
      available_promo: [],
      redeemed_promo: [],
      customerData: [],
      allVouchers: [],
      showLoader: true,
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }

    if (uniqueID === "") {
      props.history.push("/home");
    }

    if (cookie.load("vouchers_show") !== undefined) {
      cookie.remove("vouchers_show", { path: "/" });
    }

    if (cookie.load("vouchers_from") !== undefined) {
      cookie.remove("vouchers_from", { path: "/" });
    }

    var customerId = cookie.load("UserId");
    this.props.getCustomerDetails("&customer_id=" + customerId);
    this.props.getProductList(
      "uniqueID=" + uniqueID + "&productType=" + productType+'&ignoreRecommended=Yes'
    );
    this.props.getPromotionList();
    this.loadCategory();
    this.getAllProductData();
  }
  componentDidMount() {
    //$("body").addClass("hide-overlay");
  }
  componentWillReceiveProps(PropsDt) {
    if (
      this.state.urlPath !== PropsDt.match.path &&
      this.state.voucherType !== ""
    ) {
      var voucherPageName = PropsDt.match.path;
      var voucherType = "points";
      var current_page = "Vouchers";
      var productType = "5";
      var appcond = '';
      if (voucherPageName === "/buy-vouchers") {
        voucherType = "buyvoucher";
        current_page = "Buy Vouchers";
        productType = "7";
      }else {
        appcond = '&uniqueID='+uniqueID
      }
      this.setState(
        {
          current_page: current_page,
          selected_cate_id:  (voucherPageName==='/vouchers')?'all':"companycat",
          voucherType: voucherType,
          urlPath: PropsDt.match.path,
          showLoader: true,
        },
        function () {
          this.props.getProductList(
            "uniqueID=" + uniqueID + "&productType=" + productType+appcond
          );
          this.loadCategory();
          this.getAllProductData();
        }
      );
    }

    if (this.state.customerData !== PropsDt.customerdetails) {
      this.setState({ customerData: PropsDt.customerdetails });
    }
    if (this.state.productList !== PropsDt.productlist) {
      this.setState({
        productList: PropsDt.productlist,
      });
    }
    if (this.state.proapi_status !== PropsDt.faildProduct) {
      this.setState({
        proapi_status: "done",
      });
      if(PropsDt.faildProduct==='error') {
        this.setState({productList:[]});
      }
    }
    if (this.state.promotionlist !== PropsDt.promotionlist) {
      let availablePromo = [];
      let redeemedPromo = [];
      let availablePromoCount = 0;
      if (Object.keys(PropsDt.promotionlist).length > 0) {
        availablePromo = PropsDt.promotionlist.availableVoucher;
        redeemedPromo = PropsDt.promotionlist.usedVoucher;
        availablePromoCount = Object.keys(
          PropsDt.promotionlist.availableVoucher
        ).length;
      }
      this.setState({
        promotionlist: PropsDt.promotionlist,
        available_promo_count: availablePromoCount,
        available_promo: availablePromo,
        redeemed_promo: redeemedPromo,
      });
    }
  }

  loadCategory() {
    axios
      .get(apiUrl + "product/productcategories", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          let catApiStatusArr = [];
          res.data.result.map((category) => {
            catApiStatusArr["cat_" + category._id] = {
              apistatus: "initail",
              prodata: [],
            };
            return category;
          });
          this.setState(
            {
              category_list: res.data.result,
              catapi_status: catApiStatusArr,
            },
            function () {
              this.getCatProductData();
            }
          );
        }
      }).catch((e) => {
        console.error("Error fetching data:", e);
      });
  }

  getCatProductData() {
    let categoryList = this.state.category_list;
    let catapiStatus = this.state.catapi_status;
    if (Object.keys(categoryList).length > 0) {
      categoryList.map((category) => {
        let catapiData = catapiStatus["cat_" + category._id] || [];
        if (Object.keys(catapiData).length > 0) {
          this.getProductData(category._id);
        }
        return category;
      });
    }
  }

  getProductData(cat_id) {
    let catapiStatus = this.state.catapi_status;
    /* let catapiData =
      catapiStatus["cat_" + cat_id] != undefined &&
      catapiStatus["cat_" + cat_id] != ""
        ? catapiStatus["cat_" + cat_id]
        : []; */
    //showLoaderLst('trans-page-inner','class');
    var proType = 1;
    var appcond = '';
    if (this.state.voucherType === "buyvoucher") {
      proType = 7;
    }else {
      appcond = '&uniqueID='+uniqueID
    }
    axios
      .get(
        apiUrl +
          "products/voucherList?company_category=" +
          cat_id +
          "&productType=" +
          proType+appcond+'&ignoreRecommended=Yes',
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
      )
      .then((res) => {
        //hideLoaderLst('trans-page-inner','class');
        if (res.data.status === "ok") {
          catapiStatus["cat_" + cat_id] = {
            apistatus: "done",
            prodata: res.data.result,
          };
          this.setState({ catapi_status: catapiStatus });
        } else {
          catapiStatus["cat_" + cat_id] = {
            apistatus: "done",
            prodata: [],
          };
          this.setState({ catapi_status: catapiStatus });
        }
      }).catch((e) => {
        catapiStatus["cat_" + cat_id] = {
          apistatus: "done",
          prodata: [],
        };
        this.setState({ catapi_status: catapiStatus });
        console.error("Error fetching data:", e);
      });
  }

  getAllProductData() {
    var proType = 5;
    var appcond = '';
    if (this.state.voucherType === "buyvoucher") {
      proType = 7;
    }else {
      appcond = '&uniqueID='+uniqueID
    }
    
    axios
      .get(
        apiUrl + `products/voucherList?productType=${proType}${appcond}&ignoreRecommended=Yes`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({
            allVouchers: res.data.result,
            showLoader: false,
          });
        } else {
          this.setState({
            allVouchers:[],
            showLoader: false,
          });
        }
      }).catch((e) => {
        console.error("Error fetching data:", e);
      });
  }

  nevTabFun(tabTxt, event) {
    event.preventDefault();
    this.setState({ activetab: tabTxt, selected_cate_id: "companycat" });
  }

  allVouchersList() {
    let selectedCateId = this.state.selected_cate_id;
    if (this.state.proapi_status === "done") {
      let productList =
        this.state.selected_cate_id === "all"
          ? this.state.allVouchers
          : this.state.productList;
      if (Object.keys(productList).length > 0) {
        const productListHtml = productList.map((product, rwInt) => {
          let proImg = (product?.productThumbnail !== "")
              ? product.productThumbnail
              : noImage;
          let proName =
            product.productAliasName !== ""
              ? product.productAliasName
              : product.productName;
          let productPrice =
            product.productPrice !== ""
              ? parseFloat(product.productPrice)
              : 0;
          let productCost = product?.productCost || 0;
          let productTagInfo = product?.productTagInfo || '';
          let productTotalBuy = product?.productTotalBuy || 0;
          let companyLatitude = product.company?.companyLatitude||'';
          let companyLongitude = product.company?.companyLongitude || '';
          var distance = product?.distance || '';
          return (
            <div
              className="card"
              key={rwInt}
              onClick={this.gotoVoucherDetails.bind(
                this,
                this.state.voucherType === "points"
                  ? "/vouchers/" + product.productslug
                  : "/buy-vouchers/" + product.productslug
              )}
            >
              <div>
                {productTagInfo !== "" && (
                    <div className="discount-badge">
                      {productTagInfo}
                    </div>
                  )}
                <div className="voucher-image">
                  <img src={proImg} alt={proName} loading="lazy" />
                </div>
              </div>
              <div className="voucher-content">
                <h3>{product.company?.companyName}</h3>
                <p>
                  <strong>{proName}</strong>
                </p>
                <p className="price">
                  <span className="current-price">
                    {this.state.voucherType === "points"
                      ? productPrice
                      : showPriceValue(productPrice)}{" "}
                    {this.state.voucherType === "points" && (
                      <img src={coin} alt="coin" className="point-coins" />
                    )}
                  </span>
                  {parseFloat(productCost)>0 && (
                      <span className="original-price">
                        {showPriceValue(productCost)}
                      </span>
                    )}
                </p>
                <div className="location">
                  <p>
                    {addressFormat(product.company?.companyUnitNumber || '', product.company?.companyFloorNumber || '', product.company?.companyAddress || '', '', product.company?.companyPostalCode || '')}
                    {distance !== "" && <>({calculateDistance(companyLatitude, companyLongitude, defaultLatitude, defaultLongitude)})</>}
                  </p>
                </div>
                {product.giftProduct !== null && (
                  <div className="location">
                    + FREE{" "}
                    {product.giftProduct.productAliasName !== null &&
                    product.giftProduct.productAliasName !== ""
                      ? product.giftProduct.productAliasName
                      : product.giftProduct.productName}
                  </div>
                )}
                <div className="rating">
                  <span>
                  {parseInt(productTotalBuy)>0 && (
                      <>{productTotalBuy} bought</>
                    )}
                    &nbsp;
                  </span>
                </div>
              </div>
            </div>
          );
        });
        return (
          <div
            className={`card-container ${
              selectedCateId === "companycat" || selectedCateId === "all"
                ? ""
                : "display-none-cls"
            }`}
          >
            {productListHtml}
          </div>
        );
      } else {
        return (
          <div
            className={
              selectedCateId === "companycat" || selectedCateId === "all"
                ? ""
                : "display-none-cls"
            }
          >
            <div className="no-voucher">No Vouchers Found</div>
          </div>
        );
      }
    } else {
      let ulclsNm =
        selectedCateId === "companycat"
          ? "intvl-catlst intvlcatid_companycat"
          : "intvl-catlst intvlcatid_companycat display-none-cls";
      return (
        <ul className={ulclsNm}>
          <li className="intvl-catlst-li loader-main-cls">
            <div className="spinner-border loader-sub-div" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </li>
        </ul>
      );
    }
  }
  gotoVoucherDetails(voucherLink) {
    this.props.history.push(voucherLink);
  }

  catVouchersList() {
    let selectedCateId = this.state.selected_cate_id;
    let categoryList = this.state.category_list;
    let catapiStatus = this.state.catapi_status;
    if (Object.keys(categoryList).length > 0) {
      const categoryListHtml = categoryList.map((category, rwInt) => {
        let catapiData =
          catapiStatus["cat_" + category._id] !== undefined &&
          catapiStatus["cat_" + category._id] !== ""
            ? catapiStatus["cat_" + category._id]
            : [];
        if (Object.keys(catapiData).length > 0) {
          if (catapiData.apistatus === "done") {
            let productList = catapiData.prodata;
            if (Object.keys(productList).length > 0) {
              const productListHtml = productList.map((product, rwInt3) => {
                let proImg =
                  (product?.productThumbnail !== "")
                    ? product.productThumbnail
                    : noImage;
                let proName =
                  product.productAliasName !== ""
                    ? product.productAliasName
                    : product.productName;
                let productPrice =
                  product.productPrice !== ""
                    ? parseInt(product.productPrice)
                    : 0;
                let productCost = product?.productCost || 0;
                let productTagInfo = product?.productTagInfo || '';
                let productTotalBuy = product?.productTotalBuy || 0;
                let companyLatitude = product.company?.companyLatitude||'';
                let companyLongitude = product.company?.companyLongitude || '';
                var distance = product?.distance || '';
                return (
                  <div
                    className="card"
                    key={rwInt3}
                    onClick={this.gotoVoucherDetails.bind(
                      this,
                      this.state.voucherType === "points"
                        ? "/vouchers/" + product.productslug
                        : "/buy-vouchers/" + product.productslug
                    )}
                  >
                    <div>
                      {productTagInfo !== "" && (
                          <div className="discount-badge">{productTagInfo}</div>
                        )}
                      <div className="voucher-image">
                        <img src={proImg} alt={proName} loading="lazy" />
                      </div>
                    </div>
                    <div className="voucher-content">
                      <h3>{product.companyName}</h3>
                      <p>
                        <strong>{proName}</strong>
                      </p>
                      <p className="price">
                        <span className="current-price">
                          {this.state.voucherType === "points"
                            ? productPrice
                            : showPriceValue(productPrice)}{" "}
                          {this.state.voucherType === "points" && (
                            <img
                              src={coin}
                              alt="coin"
                              className="point-coins"
                            />
                          )}
                        </span>
                        {parseFloat(productCost)>0 && (
                            <span className="original-price">
                              {showPriceValue(productCost)}
                            </span>
                          )}
                      </p>
                      <div className="location">
                        <p>
                        {addressFormat(product.company?.companyUnitNumber || '', product.company?.companyFloorNumber || '', product.company?.companyAddress || '', '', product.company?.companyPostalCode || '')}
                        {distance !== "" && <>({calculateDistance(companyLatitude, companyLongitude, defaultLatitude, defaultLongitude)})</>}
                        </p>
                      </div>
                      <div className="rating">
                        <span>
                          {parseInt(productTotalBuy)>0 && (
                              <>{productTotalBuy} bought</>
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              });

              return (
                <div
                  className={`card-container ${
                    selectedCateId === category._id
                      ? ""
                      : "display-none-cls"
                  }`}
                >
                  {productListHtml}
                </div>
              );
            } else {
              let ulclsNm =
                selectedCateId === category._id
                  ? "intvl-catlst intvlcatid_" + category._id
                  : "intvl-catlst intvlcatid_" +
                    category._id +
                    " display-none-cls";
              return (
                <div className={ulclsNm}>
                  <div className="no-voucher">No Vouchers Found </div>
                </div>
              );
            }
          } else {
            let ulclsNm =
              selectedCateId === category._id
                ? "intvl-catlst intvlcatid_" + category._id
                : "intvl-catlst intvlcatid_" +
                  category._id +
                  " display-none-cls";
            return (
              <ul className={ulclsNm}>
                <li className="intvl-catlst-li loader-main-cls">
                  <div className="spinner-border loader-sub-div" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </li>
              </ul>
            );
          }
        }
        return "";
      });

      return categoryListHtml;
    }
  }

  gotoMyVoucher(voucherData, validity, event) {
    event.preventDefault();
    if(validity!=="Expired") {
      
      let voucherDataTxt =
        Object.keys(voucherData).length > 0 ? JSON.stringify(voucherData) : "";
      localStorage.setItem("voucherData", voucherDataTxt);
      this.props.history.push("/myvoucher");
    }
  }

  availableVouchersList() {
    let availablePromoLst = this.state.available_promo;
    if (Object.keys(availablePromoLst).length > 0) {
      let availVoucher = 0;
      const availablePromoListHtml = availablePromoLst.map(
        (availablePromo, rwInt) => {
          let proImg =  availablePromo?.voucherProductImage ||  noImage;
          let proName =  availablePromo.voucherProductName || '';
          let productPrice =  availablePromo?.voucherAmount || '';
          var dispChecking = "Yes";
          if (this.state.selected_cate_id === "all") {
          } else if (this.state.selected_cate_id !== "companycat") {
              if ( availablePromo.voucherProductCategoryID !== this.state.selected_cate_id ) {
                dispChecking = "No";
              }
          } else {
            if (availablePromo.voucherCompanyUniqueID !== uniqueID) {
              dispChecking = "No";
            }
          }
          if (dispChecking === "Yes") {
           var validity =  calculateDays(new Date(), availablePromo.voucherProductEndDate);
            availVoucher++;
            return (
              <div
                className="card"
                key={rwInt}
                onClick={this.gotoMyVoucher.bind(this, availablePromo, validity)}
              >
                <div>
                  <div className="voucher-image">
                    <img src={proImg} alt={proName} loading="lazy" />
                    <div className="discount-badge">{validity}</div>
                  </div>
                </div>
                <div className="voucher-content">
                  
                  <h3>{availablePromo.company?.companyName}</h3>
                  <p>
                    <strong>{proName}</strong>
                  </p>
                  <p className="price">
                    <span className="current-price">
                      {availablePromo.promotion_created_from !== "Cron" && (
                        <>
                          {productPrice}{" "}
                          <img src={coin} alt="coin" className="point-coins" />
                        </>
                      )}
                    </span>
                  </p>
                  <div className="redeem-btn">
                    <a
                      href={() => false}
                      onClick={this.gotoMyVoucher.bind(this, availablePromo, validity)}
                      title="Vouchers View"
                      className="button"
                    >
                      Redeem Now
                    </a>
                  </div>
                  <div className="rating">
                    <span>Valid Till <label>{format(new Date(availablePromo.voucherProductEndDate), "dd/MM/yyyy")}</label></span>
                  </div>
                </div>
              </div>
            );
          }
          return "";
        }
      );
      if (availVoucher > 0) {
        return <div className={`card-container`}>{availablePromoListHtml}</div>;
      } else {
        return (
          <div>
            {" "}
            <div className="no-voucher">No Vouchers Found</div>{" "}
          </div>
        );
      }
    } else {
      return (
        <div>
          {" "}
          <div className="no-voucher">No Vouchers Found</div>{" "}
        </div>
      );
    }
  }

  redeemedVouchersList() {
    let redeemedPromoLst = this.state.redeemed_promo;
    if (Object.keys(redeemedPromoLst).length > 0) {
      const redeemedPromoListHtml = redeemedPromoLst.map(
        (redeemedPromo, rwInt) => {
          let proImg =  redeemedPromo?.voucherProductImage ||  noImage;
          let proName =  redeemedPromo.voucherProductName || '';
          let productPrice =  redeemedPromo?.voucherAmount || '';
          return (
            <div className="card" key={rwInt}>
              <div>
                <div className="voucher-image">
                  <img src={proImg} alt={proName} loading="lazy" />
                </div>
              </div>
              <div className="voucher-content">
                <p>
                  <strong>{proName}</strong>
                </p>
                <p className="price">
                  <span className="current-price">
                    {redeemedPromo.promotion_created_from !== "Cron" && (
                      <>
                        {productPrice}{" "}
                        <img src={coin} alt="coin" className="point-coins" />
                      </>
                    )}
                  </span>
                </p>

                <div className="rating">
                  <span>Redeem On {format(new Date(redeemedPromo.voucherProductRedeemDate), "dd/MM/yyyy")}</span>
                </div>
              </div>
            </div>
          );
        }
      );

      return <div className={`card-container`}>{redeemedPromoListHtml}</div>;
    } else {
      return (
        <div>
          <div className="no-voucher"> No Vouchers Found </div>
        </div>
      );
    }
  }

  selectedCat(category, event) {
    event.preventDefault();
    let selected_cate_id =
      category === "companycat" || category === "all"
        ? category
        : category._id;
    this.setState({ selected_cate_id: selected_cate_id });
  }

  catListing() {
    let categoryList = this.state.category_list;
    let selected_cate_id = this.state.selected_cate_id;
    let selectedcompany_data = this.state.selectedcompany_data;
    if (Object.keys(categoryList).length > 0) {
      let msnClsTxt = selected_cate_id === "companycat" ? "active" : "";
      return (
        <OwlCarousel options={categorySetting}>
          <div
            className={selected_cate_id === "all" ? "active" : ""}
            onClick={this.selectedCat.bind(this, "all")}
            key={"01"}
          >
            <a href={() => false}>All</a>
          </div>
          {Object.keys(selectedcompany_data).length > 0 && this.state.voucherType!=='points' && (
            <div
              className={msnClsTxt}
              onClick={this.selectedCat.bind(this, "companycat")}
              key={"02"}
            >
              <a href={() => false}>{selectedcompany_data.companyName}</a>
            </div>
          )}
          {categoryList.map((category, rwInt) => {
            let msnCls = category._id === selected_cate_id ? "active" : "";
            return (
              <div
                className={msnCls}
                onClick={this.selectedCat.bind(this, category)}
                key={rwInt}
              >
                <a href={() => false}>{category.cate_name}</a>
              </div>
            );
          })}
        </OwlCarousel>
      );
    }
  }

  render() {
    let activetab = this.state.activetab;
    let myAvblPoints = 0;
    let customerData = this.state.customerData;
    if (Object.keys(customerData).length > 0) {
      myAvblPoints = customerData.customerAvailablePoints !== "" ? customerData.customerAvailablePoints : 0;
    }
    return (
      <div className="main-div">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel">
          <div className="container">
            {this.state.voucherType === "points" && (
              <>
                <div className="vouchers-mypoints">
                  <div className="overview">
                    <h5>Available Points</h5>
                    <h2>
                      {myAvblPoints} <img src={coin} alt="Coin" />
                    </h2>
                  </div>
                  {/*  <span>
                <b>Avbl. Points: </b>
                {myAvblPoints + " "} <img src={coin} />
              </span> */}
                </div>
                <div className="vouchers-hwitw">
                  <Link to={"/how-it-works"}>
                    <img src={gui} alt="hot it works" />{" "}
                    <span>How it works</span>
                  </Link>
                </div>
              </>
            )}
            <div className="vouchers-list textcenter">
              <div className="vouchers-nav">
                <ul>
                  <li
                    className={activetab === "all" ? "active" : ""}
                    onClick={this.nevTabFun.bind(this, "all")}
                  >
                    <a href={() => false}>All Vouchers</a>
                  </li>
                  <li
                    className={activetab === "available" ? "active" : ""}
                    onClick={this.nevTabFun.bind(this, "available")}
                  >
                    <a href={() => false}>
                      Available ({this.state.available_promo_count})
                    </a>
                  </li>
                  <li
                    className={activetab === "redeemed" ? "active" : ""}
                    onClick={this.nevTabFun.bind(this, "redeemed")}
                  >
                    <a href={() => false}>Redeemed</a>
                  </li>
                </ul>
              </div>
              {(activetab === "all" || activetab === "available") && (
                <div className="vouchers-category">
                  <div className="vouchers-cainner">{this.catListing()}</div>
                </div>
              )}
              {this.state.showLoader === true ? (
                <div className="vouchers-list-body">
                  <div className="card-container">
                    <div className="card" key={0}>
                      <Instagram
                        backgroundColor={"#c7c7c7"}
                        foregroundColor={"#c7c7c7"}
                      />
                    </div>

                    <div className="card" key={1}>
                      <Instagram
                        backgroundColor={"#c7c7c7"}
                        foregroundColor={"#c7c7c7"}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="vouchers-list-body">
                  {activetab === "all" && this.allVouchersList()}
                  {activetab === "all" && this.catVouchersList()}
                  {activetab === "available" && this.availableVouchersList()}
                  {activetab === "redeemed" && this.redeemedVouchersList()}
                </div>
              )}
            </div>
          </div>
        </div>
        <footer className="footer-main">
          <IonFooter collapse="fade">
            <div className="nav-full-two">
              <Link
                to={"/vouchers"}
                className={this.state.voucherType === "points" ? "active" : ""}
              >
                Points Voucher
              </Link>
              <Link
                to={"/buy-vouchers"}
                className={
                  this.state.voucherType === "buyvoucher" ? "active" : ""
                }
              >
                Buy Voucher
              </Link>
            </div>
          </IonFooter>
        </footer>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var customerdetailsArr = [];
  var faildProduct = '';
  if (Object.keys(state.customerdetails).length > 0) {
    if (state.customerdetails[0].status === "ok") {
      customerdetailsArr = state.customerdetails[0].result
    }
  }
  var productlistArr = [];
  if (Object.keys(state.productlist).length > 0) {
    if (state.productlist[0].status === "ok") {
      productlistArr = state.productlist[0].result;
    }else if (state.productlist[0].status === "error") {
      faildProduct = 'error'
    }
  }
  var promotionlistArr = [];
  if (Object.keys(state.promotionlist).length > 0) {
    if (state.promotionlist[0].status === "ok") {
      promotionlistArr = state.promotionlist[0].result;
    }
  }

  return {
    customerdetails: customerdetailsArr,
    productlist: productlistArr,
    promotionlist: promotionlistArr,
    faildProduct:faildProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAILS, params });
    },
    getProductList: (params) => {
      dispatch({ type: GET_PRODUCT_LIST, params });
    },
    getPromotionList: (params) => {
      dispatch({ type: GET_PROMOTION_LIST, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Vouchers));
