import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl, uniqueID } from "../Settings/Config";
import { showPriceSeparate } from "../Helpers/SettingHelper";
import selectedVoucher from "../../common/images/selectedVoucher.png";
import blueround from "../../common/images/blueround.png";
import startop from "../../common/images/star-top.png";
import starbottom from "../../common/images/star-bottom.png";
class VoucherList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      voucherList: [],
    };
  }

  componentDidMount() {
    this.getProductData();
  }
  getProductData() {
    axios
      .get(
        `${apiUrl}product/voucherList?uniqueID=${uniqueID}&productType=7&recomentedVoucher=Yes`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ voucherList: res.data.result });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  gotoVoucherDetails(voucher) {
    this.props.sateValChange("clickVoucher", voucher);
  }

  render() {
    return (
      <div className="directpay-new home-page">
        <div>
          {this.state.voucherList.length > 0 && (
            <div className="vouchersList">
              <h1>Buy Vouchers</h1>
              <div className="vouchers ">
                {this.state.voucherList.map((item, index) => {
                  if (index <= 2) {
                    return (
                      <div
                        key={index}
                        onClick={this.gotoVoucherDetails.bind(this, item)}
                      >
                        <div
                          className={`voucher${
                            item.giftProduct !== null ? " avail-free" : ""
                          } ${index === 0 ? "selected" : ""}`}
                        >
                          <div>
                            {index === 0 ? (
                              <>
                                <img
                                  src={selectedVoucher}
                                  className="tickimg"
                                  alt="tickimg"
                                />
                                <img src={startop} className="startop" alt="startop" />
                              </>
                            ) : (
                              <img src={blueround} className="tickimg" alt="tickimg" />
                            )}
                            <h2>
                              <span>{item.discountPrecentage}%</span>
                              <br />
                              {this.state.companyBonusType === "2"
                                ? "Discount"
                                : "Bonus"}
                            </h2>
                          </div>
                          <div className="pay-amount-parent">
                            <p>Pay</p>
                            <p className="pay-amount">
                              {showPriceSeparate(item.productPrice, 0, "no")}
                            </p>
                          </div>

                          <p className="plus">+</p>

                          <div className="bonus">
                            <p>Bonus</p>
                            <p className="bonus-amount">
                              {this.state.companyBonusType === "2"
                                ? item.discountPrecentage + "%"
                                : showPriceSeparate(
                                    item.productVoucherBonusAmount,
                                    0,
                                    "no"
                                  )}
                            </p>
                            {index === 0 && (
                              <img src={starbottom} className="starbottom" alt="starbottom" />
                            )}
                          </div>
                        </div>
                        {item.giftProduct !== null && (
                          <div className="free-vouchers">
                            + FREE{" "}
                            {item.giftProduct.productAliasName !== null &&
                            item.giftProduct.productAliasName !== ""
                              ? item.giftProduct.productAliasName
                              : item.giftProduct.productName}
                          </div>
                        )}
                      </div>
                    );
                  }else {
                    return '';
                  }
                })}
              </div>
              {this.props.showViewAll === true && (
                <div className="arrow">
                  <Link to={"/buy-vouchers"} className="arrow-link">
                    View All Vouchers
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default VoucherList;
