/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_STATICBLOCKS_LIST, SET_STATICBLOCKS_LIST } from "../actions";
import { apiUrl } from "../components/Settings/Config";
import Axios from "axios";

export const watchGetStaticblocksList = function* () {
  yield takeEvery(GET_STATICBLOCKS_LIST, workerGetStaticblocksList);
};

function* workerGetStaticblocksList(reqData) {
  try {
    var params = reqData?.params ? "?" + reqData?.params : "";
    const uri = apiUrl + "staticblock/getlist" + params;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_STATICBLOCKS_LIST, value: resultArr });
  } catch (e) {
    let returnerror = [];
    returnerror.push(e?.response?.data || []);
    yield put({ type: SET_STATICBLOCKS_LIST, value: returnerror });
  }
}
