/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_SETTINGS, SET_SETTINGS } from "../actions";
import { apiUrl, uniqueID } from "../components/Settings/Config";
import Axios from "axios";

export const watchGetSetting = function* () {
  yield takeEvery(GET_SETTINGS, workerGetSetting);
};

function* workerGetSetting() {
  try {
    const uri = apiUrl + "settings/getSettings?uniqueID=" + uniqueID;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_SETTINGS, value: resultArr });
  } catch (e) {
    let returnerror = [];
    returnerror.push(e?.response?.data || []);
    yield put({ type: SET_SETTINGS, value: returnerror });
  }
}
