/* Live */
export const uniqueID =
  localStorage.getItem("company_app_id") === null ||
  localStorage.getItem("company_app_id") === undefined
    ? ""
    : localStorage.getItem("company_app_id");
export const companyLogo =
  localStorage.getItem("companyLogo") === null ||
  localStorage.getItem("companyLogo") === undefined
    ? ""
    : localStorage.getItem("companyLogo");

export const apiUrl = "https://walletapi.epicpay.ai/api/";
export const baseUrl = "https://wallet.epicpay.ai/";


/* export const apiUrl = "http://localhost:3005/api/";
export const baseUrl = "http://localhost:3001/";   */


export const stripeReference = "epicpay";

export const defaultLatitude = '1.290270';
export const defaultLongitude = '103.851959';