import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import Header from "../Layout/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookie from "react-cookies";
import { GET_CUSTOMER_DETAILS } from "../../actions";
import { apiUrl, uniqueID, baseUrl } from "../Settings/Config";
import { showLoaderLst, hideLoaderLst } from "../Helpers/SettingHelper";

import shbg from "../../common/images/sharebg.png";
import coinw from "../../common/images/coin.svg";
import bagin from "../../common/images/bag.svg";
import giftbx from "../../common/images/gift-box.svg";
import orderfood from "../../common/images/order-food.svg";

var qs = require("qs");
class Usershare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: "Share",
      customerData: [],
      points_settings: [],
      customer_referral_code: "",
      share_flag: "initial",
      referee_email_id: "",
      regcust_sucess: "",
      regcust_error: "",
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }

    if (uniqueID === "") {
      props.history.push("/home");
    }

    var customerId = cookie.load("UserId");
    this.props.getCustomerDetails("&customer_id=" + customerId);
  }
  componentWillReceiveProps(PropsDt) {
    if (this.state.customerData !== PropsDt.customerdetails) {
      let customer_referral_code = "";
      let points_settings = [];
      if (Object.keys(PropsDt.customerdetails).length > 0) {
        points_settings = PropsDt.customerdetails.pointsSettings.length > 0
            ? PropsDt.customerdetails.pointsSettings
            : [];
        customer_referral_code = PropsDt.customerdetails.customerReferralCode;
      }
      this.setState({
        customerData: PropsDt.customerdetails,
        points_settings: points_settings,
        customer_referral_code: customer_referral_code,
      });
    }
  }

  nevMemberFun(tabTxt, event) {
    event.preventDefault();
    this.setState({ activemember: tabTxt });
  }

  userReferral_old(actTxt, event) {
    event.preventDefault();
    if (actTxt === "initial") {
      this.setState({ share_flag: "share" });
    } else if (actTxt === "cancel") {
      this.setState({
        share_flag: "initial",
        referee_email_id: "",
        regcust_error: "",
      });
    } else if (actTxt === "share") {
      var errorMgs = "";
      let referee_email_id = this.state.referee_email_id;
      if (referee_email_id === "") {
        errorMgs = "Referee Email required";
      }
      if (errorMgs === "") {
        this.setState({ regcust_error: "" });

        var postObject = {
          uniqueID: uniqueID,
          referee_email_id: referee_email_id,
          customer_id: cookie.load("UserId"),
          customer_referral_code: this.state.customer_referral_code,
        };
        showLoaderLst("cust-referal-btn", "class");
        axios
          .post(
            apiUrl + "customer/userreferal",
            qs.stringify(postObject),
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            hideLoaderLst("cust-referal-btn", "class");
            if (res.data.status === "ok") {
              this.setState({
                regcust_sucess: "Referral code shared successfully",
              });
              let $_this = this;
              setTimeout(function () {
                $_this.props.history.push("/myaccount");
              }, 1000);
            } else {
              this.setState({ regcust_error: res.data.message });
            }
          }).catch((e) => {
            console.error("Error fetching data:", e);
          });
      } else {
        this.setState({ regcust_error: errorMgs });
      }
    }
  }

  userReferral(actTxt, event) {
    event.preventDefault();
    if (actTxt === "initial") {
      this.setState({ share_flag: "share" });
    } else if (actTxt === "cancel") {
      this.setState({
        share_flag: "initial",
        referee_email_id: "",
        regcust_error: "",
      });
    } else if (actTxt === "share") {
      var errorMgs = "";
      let customer_referral_code = this.state.customer_referral_code;
      if (customer_referral_code === "") {
        errorMgs = "Customer referral code required";
      }
      if (errorMgs === "") {
        this.setState({ regcust_error: "" });
      } else {
        this.setState({ regcust_error: errorMgs });
      }
    }
  }

  handleFldChange(event) {
    this.setState({ regcust_error: "", referee_email_id: event.target.value });
  }

  copyLink(itemSlug) {
    toast.success("Link Copied Success", { autoClose: 1000 });
    navigator.clipboard.writeText(itemSlug);
  }

  render() {
    let pointsSettings = this.state.points_settings;
    return (
      <div className="main-div">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />
        <ToastContainer />
        <div className="rel">
          <div className="share-bg textcenter">
            <img src={shbg} alt="bg" />
          </div>
          <div className="container">
            {Object.keys(pointsSettings).length > 0 && (
              <div className="u-willget">
                <h2>You will get</h2>
                <div className="credit-list">
                  <ul>
                    <li>
                      <div className="list-parent">
                        <div className="buy-credit-u">
                          <span>
                            When your referrals make <br />
                            their <strong>1st wallet topup</strong>
                          </span>
                        </div>
                        <div className="coin-price">
                          {
                            pointsSettings[0]
                              .pntset_first_transaction_bonuspoints
                          }{" "}
                          <img src={coinw} alt="coin" />{" "}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="list-parent">
                        <div className="buy-credit-u">
                          <span>
                            When your <br />
                            <strong>referrals signup</strong>
                          </span>
                        </div>
                        <div className="coin-price">
                          {
                            pointsSettings[0]
                              .pntset_referrals_signup_bonuspoints
                          }{" "}
                          <img src={coinw} alt="coin" />{" "}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <div className="double-fun">
              <div className="double-fun-header">
                <h2>Refer & Earn</h2>
                {/* <a href="/">Learn more</a> */}
              </div>
              <ul>
                <li>
                  <figure className="of-icon">
                    <img src={orderfood} alt="orderfood" />
                  </figure>
                  <span>Refer using your unique link on your socials</span>
                </li>
                <li>
                  <figure className="bg-icon">
                    <img src={bagin} alt="bagin" />
                  </figure>
                  <span>When your referrals make their first transaction, you earn Vocuher</span>
                </li>
               {/*  <li>
                  <figure className="gb-icon">
                    <img src={giftbx} alt="giftbx" />
                  </figure>
                  <span>
                    When your referrals make their first transaction, you earn
                    20 points
                  </span>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        {this.state.customer_referral_code !== "" && (
          <IonFooter collapse="fade">
            {this.state.share_flag === "share" ? (
              <div className="update-personal">
                {this.state.regcust_sucess === "" ? (
                  <div className="up-form refral-frm">
                    {/*<input type="text" placeholder="Referee Email Address" name="referee_email_id" value={this.state.referee_email_id} onChange={this.handleFldChange.bind(this)} />*/}
                    <div className="share-input">
                      <input
                        type="text"
                        placeholder="Referee Url"
                        name="referee_url"
                        defaultValue={
                          baseUrl +
                          "refpage/referral/" +
                          this.state.customer_referral_code
                        }
                      />
                      <div
                        className="cpy-icon"
                        onClick={this.copyLink.bind(
                          this,
                          baseUrl +
                            "refpage/referral/" +
                            this.state.customer_referral_code
                        )}
                      >
                        <i className="fa fa-clone" aria-hidden="true"></i>
                      </div>
                    </div>
                    {this.state.regcust_error !== "" && (
                      <p className="error_info">{this.state.regcust_error}</p>
                    )}
                    <div className="up-frm-btn">
                      <a
                        href="/"
                        className="button ghost-btn"
                        onClick={this.userReferral.bind(this, "cancel")}
                      >
                        Cancel
                      </a>
                      {/*<a href="/" className="button cust-referal-btn" onClick={this.userReferral.bind(this,'share')}>
                    Share
                  </a>*/}
                      <a
                        href={
                          "whatsapp://send?text=" +
                          baseUrl +
                          "refpage/eferral/" +
                          this.state.customer_referral_code
                        }
                        className="button cust-referal-btn"
                      >
                        Share
                      </a>
                    </div>
                  </div>
                ) : (
                  <p className="sucess-msg">{this.state.regcust_sucess}</p>
                )}
              </div>
            ) : (
              <div className="sticky-single-btn">
                <a
                  href="/"
                  className="button "
                  onClick={this.userReferral.bind(this, "initial")}
                >
                  Share to the friends
                </a>
              </div>
            )}
          </IonFooter>
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var customerdetailsArr = [];
  if (Object.keys(state.customerdetails).length > 0) {
    if (state.customerdetails[0].status === "ok") {
      customerdetailsArr = state.customerdetails[0].result;
    }
  }
  return {
    customerdetails: customerdetailsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAILS, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Usershare));
