/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_PROMOTION_LIST, SET_PROMOTION_LIST } from "../actions";
import { apiUrl, uniqueID } from "../components/Settings/Config";
import Axios from "axios";

export const watchGetPromotionList = function* () {
  yield takeEvery(GET_PROMOTION_LIST, workerGetPromotionList);
};

function* workerGetPromotionList(reqData) {
  try {
    var params = reqData?.params || '';
    const uri = `${apiUrl}voucher/getMyVoucher?uniqueID=${uniqueID}${params}`;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PROMOTION_LIST, value: resultArr });
  } catch (e) {
    let returnerror = [];
    returnerror.push(e?.response?.data || []);
    yield put({ type: SET_PROMOTION_LIST, value: returnerror });
  }
}
