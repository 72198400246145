import React, { Component } from "react";
import Header from "../Layout/Header";
import cookie from "react-cookies";
import { uniqueID } from "../Settings/Config";
import UploadReceiptDetails from "./UploadReceiptDetails";

class ReceiptUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: "Upload Receipt",
      backLink:"Home"
    };

    if (cookie.load("UserId") === undefined) {
    //  props.history.push("/");
    }

    if (uniqueID === "") {
      //props.history.push("/home");
    }
  }

 

  render() {
    return (
      <div className="directpay-new">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />
        <UploadReceiptDetails props={this.props} />
      </div>
    );
  }
}

export default ReceiptUploader;
