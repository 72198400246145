import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import cookie from "react-cookies";
import update from "immutability-helper";
import axios from "axios";
import {  Camera } from "lucide-react";
import {GET_REWARDSETTING_LIST } from "../../actions";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { apiUrl, uniqueID, baseUrl } from "../Settings/Config";
import {
  showLoaderLst,
  hideLoaderLst,
  showPriceValue, showPriceSeparate
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import VoucherList from "../Module/VoucherList";
import Login from "../Module/Login";
import menuicon from "../../common/images/menu-icon.svg";
import paybillicon from "../../common/images/paybill-icon.svg";
import offerimage from "../../common/images/offerimage.png";

var qs = require("qs");
class DirectPayHome extends Component {
  constructor(props) {
    super(props);
    const loginType = cookie.load("loginType") || "";

    var refcode =
      localStorage.getItem("refcode") === null
        ? ""
        : localStorage.getItem("refcode");
    var triggerOTP = "No";
    var triggerFrom = "";
    var bottompopup = "regphone";
    var reg_phone_no = "";
    if (cookie.load("triggerOTP") === "Yes") {
      triggerOTP = "Yes";
      triggerFrom = cookie.load("triggerFrom");
      reg_phone_no = cookie.load("UserMobile");
      bottompopup = "regotp";
    }

    this.state = {
      refcode:refcode,
      current_page: "guesthome",
      loginType: loginType,
      designType: "new",
      withheaderbg: "yes",
      allowToShowCompany: "yes",
      gSettings: "",
      companyBonusType: "1",
      voucherList: [],

      runingNum: 0,
      bottompopup: bottompopup,
      trigger_otp: triggerOTP,
      trigger_from: triggerFrom,
      reg_phone_no: reg_phone_no,
      regphone_error: "",
      regotpdata: {
        reg_otp_dgt1: "",
        reg_otp_dgt2: "",
        reg_otp_dgt3: "",
        reg_otp_dgt4: "",
      },
      regotp_terms: "No",
      regotp_promo: "No",
      regotp_error: "",
      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      regcust_error: "",
      openLogin: false,
      clientLogo: "",
      companyName: "",
      welcomeVoucher: [],
      rewardsettingsdata:[],
      cashbackList:[],
    };
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    this.loadSettings();
    this.loadWelcomeVoucher();
    this.getCashbackData();
    var customerID = cookie.load("UserId") || "";
    this.props.getRewardSettingList("&customer_id=" + customerID);
  }
  componentWillReceiveProps(PropsDt) {
    if (this.state.rewardsettingsdata !== PropsDt.rewardsettingslist) {
      this.setState({ rewardsettingsdata: PropsDt.rewardsettingslist });
    }
  }
  loadSettings() {
    var urlShringTxt = apiUrl + "settings/getSettings/?uniqueID=" + uniqueID;
    axios
      .get(urlShringTxt, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var result = res.data.result;
          this.setState({
            gSettings: result,
            clientLogo: result?.companyLogo,
            companyName: result?.companyName,
            companyBonusType: parseInt(result?.companyBonusType) || 1,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  loadWelcomeVoucher() {
    var customerID = cookie.load("UserId") || "";
    var urlShringTxt = `${apiUrl}product/voucherList?uniqueID=${uniqueID}&productType=7&welcomeVoucher=Yes&customerID=${customerID}`;
    axios
      .get(urlShringTxt, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({
            welcomeVoucher: res.data.result,
          });
        }
      });
  }
  getCashbackData() {
    axios
      .get(
        `${apiUrl}settings/cashbackList?uniqueID=${uniqueID}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ cashbackList: res.data.result });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  handleFldChange(event) {
    const re = /^[0-9 \b]+$/;
    if (event.target.name === "reg_phone_no") {
      if (event.target.value === "" || re.test(event.target.value)) {
        var mblnumber = this.space(event.target.value);
        var mblnumberLenght = mblnumber.length;
        if (mblnumberLenght <= 9) {
          this.setState({ reg_phone_no: mblnumber });
        }
      }
    } else if (
      event.target.name === "reg_otp_dgt1" ||
      event.target.name === "reg_otp_dgt2" ||
      event.target.name === "reg_otp_dgt3" ||
      event.target.name === "reg_otp_dgt4"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        var mblnumbers = this.space(event.target.value);
        var mblnumberLenghts = mblnumbers.length;
        if (mblnumberLenghts <= 1) {
          let field = event.target.name;
          let fieldvalue = event.target.value;
          this.setState(
            update(this.state, {
              regotpdata: { [field]: { $set: fieldvalue } },
            }),
            function () {
              if (field === "reg_otp_dgt1" && mblnumberLenghts === 1) {
                $("#reg_otp_dgt2").focus();
              }
              if (field === "reg_otp_dgt2" && mblnumberLenghts === 1) {
                $("#reg_otp_dgt3").focus();
              }
              if (field === "reg_otp_dgt3" && mblnumberLenghts === 1) {
                $("#reg_otp_dgt4").focus();
              }
            }
          );
        }
      }
    } else if (event.target.name === "cust_name") {
      this.setState({ cust_name: event.target.value });
    } else if (event.target.name === "cust_email_id") {
      this.setState({ cust_email_id: event.target.value });
    } else if (event.target.name === "birthday_month") {
      this.setState({ birthday_month: event.target.value });
    } else if (event.target.name === "cust_ref_code") {
      this.setState({ cust_ref_code: event.target.value });
    }
    this.setState({ regphone_error: "", regotp_error: "", regcust_error: "" });
  }

  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }

  setRuningNum() {
    var rct_this = this;
    var runingNum = this.state.runingNum;
    var downloadTimer = setInterval(function () {
      if (runingNum <= 0) {
        clearInterval(downloadTimer);
      }
      runingNum = runingNum - 1;
      rct_this.setState({ runingNum: runingNum });
    }, 1000);
  }

  triggerOTPFunction() {
    var regphoneNo = cookie.load("UserMobile") || '';
    if (regphoneNo !== "") {
      console.log(regphoneNo, "regphoneNo");
      regphoneNo = regphoneNo.replace(/ /g, "");
      var postObject = {
        apuniqueIDp_id: uniqueID,
        customer_mobile: regphoneNo,
      };
      showLoaderLst("home-page-maindiv", "class");
      axios
        .post(apiUrl + "customer/send_otponly", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst("home-page-maindiv", "class");
          if (res.data.status === "ok") {
            this.setState(
              { bottompopup: "regotp", runingNum: 120 },
              function () {
                this.setRuningNum();
              }.bind(this)
            );
          } else {
            this.setState({ regphone_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regphone_error: "Phone number required" });
    }
  }

  regSendOtp(typeflg, event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    if (regphoneNo !== "") {
      var postObject = {
        customer_mobile: regphoneNo,
        otp_type: typeflg,
      };
      if (uniqueID !== "") {
        postObject["uniqueID"] = uniqueID;
      }
      let loadrdivcls = typeflg === "login" ? "login-otp-link" : "reg-otp-link";
      showLoaderLst(loadrdivcls, "class");
      axios
        .post(apiUrl + "customer/send_customer_otp", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst(loadrdivcls, "class");
          if (res.data.status === "ok") {
            
            if (
              typeflg === "login" ||
              (typeflg === "register" && res.data.is_existing_user === "yes")
            ) {
              
              let custArr = res.data.result;
              cookie.save("UserId", custArr.customer_id, { path: "/" });
              cookie.save("UserEmail", custArr.customer_email, { path: "/" });
              cookie.save(
                "UserFname",
                custArr.customer_first_name !== ""
                  ? custArr.customer_first_name
                  : "",
                { path: "/" }
              );
              cookie.save(
                "UserLname",
                custArr.customer_last_name !== ""
                  ? custArr.customer_last_name
                  : "",
                { path: "/" }
              );
              cookie.save("UserMobile", regphoneNo, { path: "/" });
              cookie.save("IsVerifiedUser", "No", { path: "/" });
              localStorage.setItem("token", custArr.token);
              cookie.remove("loginType", { path: "/" });
              if (uniqueID !== "") {
                localStorage.setItem("allowCompanyRedirect", "Yes");
              }
              let $_this = this;
              if (this.props.location?.state) {
                localStorage.setItem(
                  "voucherSlug",
                  $_this.props.location.state.voucherSlug
                );
                localStorage.setItem(
                  "voucherquantity",
                  $_this.props.location.state.quantity
                );
                setTimeout(function () {
                  window.location.href = baseUrl + "directpay";
                }, 500);
              } else {
                setTimeout(function () {
                  window.location.href = baseUrl + "directpay";

                  //$_this.props.history.push("/myaccount");
                }, 100);
              }
            } else {
              /*var bottompopuptxt = (typeflg == 'login')? 'loginotp' : 'regotp';*/
              this.setState({ bottompopup: "confirm" });
            }
          } else {
            this.setState({ regphone_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regphone_error: "Phone number required" });
    }
  }

  confirmPhone(event) {
    event.preventDefault();
    this.setState(
      { bottompopup: "regotp", runingNum: 120 },
      function () {
        this.setRuningNum();
      }.bind(this)
    );
  }

  displayRunNumbar() {
    if (this.state.regotp_error !== "") {
      return <p className="error_info">{this.state.regotp_error}</p>;
    } else if (this.state.runingNum > 0) {
      return (
        <p className="runing_num">Resend in {this.state.runingNum} Seconds</p>
      );
    } else {
      return <p className="resend_link">Resend</p>;
    }
  }

  chakRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    if (field === "trams") {
      var chkBox = regotp_terms === "Yes" ? true : false;
      return chkBox;
    } else if (field === "promo") {
      var chkBoxs = regotp_promo === "Yes" ? true : false;
      return chkBoxs;
    }
  }

  changeRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    if (field === "trams") {
      var regotpterms = regotp_terms === "Yes" ? "No" : "Yes";
      this.setState({ regotp_terms: regotpterms });
      if (regotp_terms === "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }

    if (field === "promo") {
      var regotpPromo = regotp_promo === "Yes" ? "No" : "Yes";
      this.setState({ regotp_promo: regotpPromo });
      if (regotpPromo === "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }
  }

  cancelAct(event) {
    event.preventDefault();
    var triggerfrom = this.state.trigger_from;
    triggerfrom =
      triggerfrom !== "" && triggerfrom !== undefined ? triggerfrom : "myaccount";
    if (triggerfrom === "checkout") {
      triggerfrom = "myaccount";
    }
    this.props.history.push("/" + triggerfrom);
  }

  tgrVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    console.log(regphoneNo, "regphoneNo");
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length !== 4) {
      errorMgs = "Please enter the OTP";
    }

    if (regphoneNo !== "" && errorMgs === "") {
      var postObject = {
        uniqueID: uniqueID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "redeempoints",
        influencerID: localStorage.getItem("influencerID"),
      };
      showLoaderLst("reg-otp-verify", "class");
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          hideLoaderLst("reg-otp-verify", "class");
          if (res.data.status === "ok") {
            cookie.save("IsVerifiedUser", "Yes", { path: "/" });
            var triggerfrom = this.state.trigger_from;
            triggerfrom =
              triggerfrom !== "" && triggerfrom !== undefined
                ? triggerfrom
                : "myaccount";
            let $_this = this;
            console.log(this.props, "this.props");
            if (this.props.location?.state) {
              setTimeout(function () {
                $_this.props.history.push({
                  pathname: $_this.props.location.state.triggerFrom,
                  state: $_this.props.location.state,
                });
              }, 500);
            } else {
              setTimeout(function () {
                $_this.props.history.push("/" + triggerfrom);
              }, 500);
            }
            return false;
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }

  regVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length !== 4) {
      errorMgs = "Please enter the OTP";
    } else if (this.state.regotp_terms !== "Yes") {
      errorMgs = "Terms & Conditions required";
    } else if (this.state.regotp_promo !== "Yes") {
      errorMgs = "Promotions required";
    }

    if (regphoneNo !== "" && errorMgs === "") {
      var postObject = {
        uniqueID: uniqueID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "register",
      };
      showLoaderLst("reg-otp-verify", "class");
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          hideLoaderLst("reg-otp-verify", "class");
          if (res.data.status === "ok") {
            this.setState({ bottompopup: "regpersonal", runingNum: 0 });
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }

  goBackTo(refPage, event) {
    event.preventDefault();
    var regotpdata = {
      reg_otp_dgt1: "",
      reg_otp_dgt2: "",
      reg_otp_dgt3: "",
      reg_otp_dgt4: "",
    };
    this.setState({
      bottompopup: refPage,
      reg_phone_no: "",
      regphone_error: "",
      regotpdata: regotpdata,
      regotp_terms: "No",
      regotp_promo: "No",
      regotp_error: "",
      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      cust_ref_code: "",
      regcust_error: "",
    });
  }

  custRegistration(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var errorMgs = "";
    if (regphoneNo === "") {
      errorMgs = "Mobile No required";
    } else if (this.state.cust_name === "") {
      errorMgs = "Name required";
    } else if (this.state.cust_email_id === "") {
      errorMgs = "Email required";
    } else if (this.state.birthday_month === "") {
      errorMgs = "Birthday month required";
    }

    if (errorMgs === "") {
      var postObject = {
        uniqueID: uniqueID,
        customerPhone: regphoneNo,
        customerEmail: this.state.cust_email_id,
        customerBirthMonth: this.state.birthday_month,
        customerReferralCode: this.state.cust_ref_code,
        customerName: this.state.cust_name,
        shareProductID: localStorage.getItem("shareProductID") || "",
        analyticsType: localStorage.getItem("analyticsType") || "",
      };
      showLoaderLst("cust-reg-btn", "class");
      axios
        .post(apiUrl + "customer/registration", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst("cust-reg-btn", "class");
          if (res.data.status === "ok") {
            //this.setState({ bottompopup: 'regpersonal', runingNum: 0 });

            let custArr = res.data.result_set;
            cookie.save("UserId", custArr.customer_id, { path: "/" });
            cookie.save("UserEmail", custArr.customer_email, { path: "/" });
            cookie.save(
              "UserFname",
              custArr.customer_first_name !== ""
                ? custArr.customer_first_name
                : "",
              { path: "/" }
            );
            cookie.save(
              "UserLname",
              custArr.customer_last_name !== ""
                ? custArr.customer_last_name
                : "",
              { path: "/" }
            );
            cookie.save("UserMobile", regphoneNo, { path: "/" });
            cookie.save("IsVerifiedUser", "Yes", { path: "/" });
            localStorage.setItem("token", custArr?.token);
            cookie.remove("loginType", { path: "/" });

            setTimeout(function () {
              window.location.href = baseUrl + "directpay";
            }, 100);
          } else {
            this.setState({ regcust_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regcust_error: errorMgs });
    }
  }

  loginVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length !== 4) {
      errorMgs = "Please enter the OTP";
    }

    if (regphoneNo !== "" && errorMgs === "") {
      var postObject = {
        uniqueID: uniqueID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "login",
      };
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({ runingNum: 0 });
            this.props.history.push("myaccount");
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        }).catch((e) => {
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }
  openLogin(e) {
    e.preventDefault();
    this.setState({ openLogin: true });
  }
  sateValChange = (field, value) => {
    if (field === "clickVoucher") {
      this.props.history.push({
        pathname: "/directpay/checkout",
        state: { selectedVoucher: value },
      });
    }else {
      this.props.history.push("/refpage/directpay");
    }
  };

  gotoWelcomeVoucher(selectedVoucher) {
    if (selectedVoucher.alreadyredeem === "no") {
      if (this.state.loginType === "Guest") {
        this.setState({ selectedVoucher: selectedVoucher, openLogin: true });
      } else {
        this.props.history.push(
          "/buy-vouchers/" + selectedVoucher.productslug
        );
      }
    }
  }

  rewardProLst(membership_spent_amount) {
      if (this.state.rewardsettingsdata.length > 0) {
        const rewardsettingsHtml = this.state.rewardsettingsdata.map(
          (rewardsettings, rwInt) => {
            let msnCls = rewardsettings.is_earned === "Yes" ? "active" : "";
            var reward_freeproduct_image =
              rewardsettings?.reward_freeproduct_image || "";
            let proImg =
              reward_freeproduct_image !== "" ? (
                <img src={reward_freeproduct_image} alt="Coin" />
              ) : (
                ""
              );
            return (
              <li key={rwInt} className={msnCls}>
                <figure>{proImg}</figure>
                <div className="reward-reach">
                  <em>{rewardsettings.reward_freeproduct_name}</em>
                  <span>
                    {showPriceValue(rewardsettings.reward_pointstoreach)}{" "}
                  </span>
                </div>
              </li>
            );
          }
        );
  
        var calcMemberPerc = 0;
        let indxVal = Object.keys(this.state.rewardsettingsdata).length - 1;
        let membership_max_amount = parseInt(
          this.state.rewardsettingsdata[indxVal].reward_pointstoreach
        );
  
        if (membership_max_amount) {
          if (
            parseFloat(membership_spent_amount) >
            parseFloat(membership_max_amount)
          ) {
            calcMemberPerc = 100;
          } else {
            calcMemberPerc =
              (membership_spent_amount / membership_max_amount) * 100;
          }
        }
  
        return (
          <>
            <div className="rewards-uget">
              <h2>Rewards You Get</h2>
              <span>Spend Your Credits To Unlock Exciting Vouchers</span>
              <ul>{rewardsettingsHtml}</ul>
            </div>
            <div className="ovr-view-inner">
              <div className="slide-frame">
                <div
                  className="inner-frame-bg"
                  style={{ width: calcMemberPerc + "%" }}
                ></div>
              </div>
              <div className="slide-frame1 active"></div>
              <div
                className={
                  parseFloat(calcMemberPerc) === 100
                    ? "slide-frame2 active"
                    : "slide-frame2"
                }
              ></div>
              {this.rewardActLst(membership_spent_amount)}
            </div>
          </>
        );
      }
    }
  
    rewardActLst(membership_spent_amount) {
      const rewardsettingsHtml = this.state.rewardsettingsdata.map(
        (rewardsettings, rwInt) => {
          let msnCls =
            parseFloat(membership_spent_amount) >=
            parseFloat(rewardsettings.reward_pointstoreach)
              ? "active"
              : "";
          return (
            <li key={rwInt} className={msnCls}>
              {showPriceValue(rewardsettings.reward_pointstoreach)}
            </li>
          );
        }
      );
  
      return (
        <ul>
          <li className="active">0</li>
          {rewardsettingsHtml}
        </ul>
      );
    }

  render() {
    console.log(this.state.gSettings)
    let companyBonusType = 1;
    if(this.state.gSettings !== "") {
      companyBonusType = this.state.gSettings?.companyBonusType || "1";
    }

    return (
      <div className="directpay-new home-page">
        <Header
          mainpagestate={this.state}
          prntPagePrps={this.props}
          sateValChange={this.sateValChange}
        />
        <div className="container">
          <div className="home-menu">
            {this.state.loginType === "Guest" && (
              <div className="login-section">
                <a
                  href="/"
                  onClick={this.openLogin.bind(this)}
                  className="login-link"
                >
                  If you already have an account,{" "}
                  <span>Click here to Login</span>
                </a>
              </div>
            )}

            <div className="button-container">
              <a href="/" className="btn view-menu">
                <img src={menuicon} alt="" />
                <span>View Menu</span>
              </a>
              {companyBonusType!=='3' && (
                <Link to={`/directpay/checkout`} className="btn pay-bill">
                  <img src={paybillicon} alt="" />
                  <span>Pay Bill</span>
                </Link>
              )}
              {companyBonusType==='3' && (
                <Link to={`/upload-receipt`} className="btn pay-bill" title="Upload Bill">
                  <Camera />
                  
                  <span>Upload Bill</span>
                </Link>
              )}
            </div>
            {this.state.welcomeVoucher.length > 0 &&
              this.state.welcomeVoucher.map((item) => {
                return (
                  <div
                    className="promotion-section"
                    onClick={this.gotoWelcomeVoucher.bind(this, item)}
                  >
                    <img
                      src={ item?.productThumbnail || offerimage }
                      alt="Breakfast offer"
                      className="promo-image"
                    />
                    <div
                      className={`promo-text ${
                        item.alreadyredeem === "yes" ? "used" : ""
                      }`}
                    >
                      <p>
                        {item.alreadyredeem === "yes"
                          ? "Already issued"
                          : "Claim Your Free Welcome Voucher"}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          {companyBonusType!=="3" &&
          <VoucherList
            companyBonusType={this.state.companyBonusType}
            sateValChange={this.sateValChange}
            showViewAll={false}
          />}
          {companyBonusType==="3" && this.state.cashbackList.length > 0 && (
            <div className="vouchersList bill-cashback">
              <h2 className="subtitle">Simply Scan Your Receipts</h2>
              <h1>Earn Cash & Points</h1>
              <div className="vouchers ">
                {this.state.cashbackList.map((item, index) => {
                  return (
                    <div
                      key={index}
                    >
                      <div
                        className={`voucher`}
                        key={index}
                      >
                        <div>
                          <h2>
                            <span>{item.cashBackPercentage}%</span>
                            <br />
                            Cashback
                          </h2>
                        </div>
                        <div className="pay-amount-parent">
                          <p>Spend above</p>
                          <p className="pay-amount">
                            {showPriceSeparate(item.fromAmount, 0, "no")}
                          </p>
                        </div>

                        <p className="plus">=</p>

                        <div className="bonus">
                          <p>Earn up to</p>
                          <p className="bonus-amount">
                          {parseFloat(item.maxAmount)>0?showPriceSeparate(item.maxAmount, 0, "no"):(item.toCashBack!==""?showPriceSeparate(item.toCashBack, 0, "no"):showPriceSeparate(item.fromCashBack, 0, "no"))}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="upcoming-trans">
            {this.rewardProLst(0)}
          </div>
        </div>

        <BottomSheet
          open={this.state.openLogin}
          onDismiss={() => {
            this.setState({ openLogin: false });
          }}
        >
          <Login sateValChange={this.sateValChange} />
        </BottomSheet>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {

  var rewardsettingsArr = [];
  if (Object.keys(state.rewardsettingslist).length > 0) {
    if (state.rewardsettingslist[0].status === "ok") {
      rewardsettingsArr = state.rewardsettingslist[0].result;
    }
  }

  return {
    rewardsettingslist: rewardsettingsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRewardSettingList: (params) => {
      dispatch({ type: GET_REWARDSETTING_LIST, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(DirectPayHome));
