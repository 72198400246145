import React, { Component } from "react";
import axios from "axios";
import cookie from "react-cookies";
import { apiUrl, baseUrl } from "../Settings/Config";
import $ from "jquery";
import Swal from "sweetalert2";
class DirectPay extends Component {
  constructor(props) {
    super(props);
    console.log(this.props, 'this.props')
    const QRCode = this.props.match.params?.QRCode||'';
    const outletSlug = this.props.match.params?.outletSlug||'';
    const slugtext = this.props.match.params?.slugtext||'';
    const merchantID = this.props.match.params?.merchantID||'';
    var userID = cookie.load('UserId') || '';
    var DirectQRCode = cookie.load('DirectQRCode') || '';
    console.log(DirectQRCode, 'DirectQRCodeDirectQRCodeDirectQRCode')
    this.state = {
      QRCode:QRCode,
      outletSlug:outletSlug,
      companyID:slugtext,
      merchantID:merchantID,
      userID:userID
    };
    /* if(DirectQRCode!=="") {
      this.props.history.push('/directpay');
    } else  */if(QRCode==='' && slugtext==="") {
      this.props.history.push('/');
    }else {
      this.checkQRCode();
    }
  }

 
  checkQRCode() {
      var postObject = {
        QRCode:this.state.QRCode,
        merchantID:this.state.merchantID,
        companyID:this.state.companyID
      };
      console.log(postObject, 'DirectQRCodeDirectQRCodeDirectQRCode')
      if(this.state.userID!=="") {
        postObject['customerID'] = this.state.userID;
      }
      axios
        .post(apiUrl + "customer/loginWithQR", postObject)
        .then((res) => {
          if (res.data.status === "ok") {
            let custArr = res.data.result;
              if(this.state.QRCode!=="") {
                cookie.save("DirectQRCode", this.state.QRCode, { path: "/" });
              }else {
                cookie.save("DirectQRCode", custArr.outletQRCode, { path: "/" });
              }
            
              localStorage.setItem('company_app_id', custArr.UniqueID);
              localStorage.setItem('company_logo', custArr.companyLogo);
             
              cookie.save("UserMobile", custArr.customer_phone, { path: "/" });
              cookie.save("UserId", custArr.customer_id, { path: "/" });
              cookie.save("UserEmail", custArr.customer_email, { path: "/" });
              cookie.save("outletID", custArr.outletID, { path: "/" });
              cookie.save("company_app_id", custArr.UniqueID, { path: "/" });
              if(custArr.customer_default===1) {                
                cookie.save("loginType", 'Guest', { path: "/" });
              }    
              
              cookie.save(
                "UserFname",
                custArr.customer_first_name !== ""
                  ? custArr.customer_first_name
                  : "",
                { path: "/" }
              );
              cookie.save(
                "UserLname",
                custArr.customer_last_name !== ""
                  ? custArr.customer_last_name
                  : "",
                { path: "/" }
              );
              cookie.save("IsVerifiedUser", "No", { path: "/" });
              localStorage.setItem("token", custArr.token);
              console.log(custArr.customer_default, 'custArr.customer_default')
              setTimeout(() => {
                window.location.href = baseUrl + "directpay";
                /* if(custArr.customer_default===1) {
                  window.location.href = baseUrl + "directpay";
                }else {
                  window.location.href = baseUrl + "myaccount";
                } */
                this.setState({ position: 1 });
              }, 100);
          } else {
            this.setState({ regphone_error: res.data.message });
          }
        }).catch((e)=> {
          var errorMsg = e?.response?.data?.massage || e.message;
          Swal.fire({
            title: "Error",
            html: errorMsg,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
          this.props.history.push('/')
        });
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
   
  }

  render() {
   
      return (
        <div className="container">
          <div id="loading-indicator">
            <div className="lds-hourglass"></div>
          </div>
        </div>
      );
  }
}

export default DirectPay;
