import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import cookie from "react-cookies";
import axios from "axios";
import Swal from "sweetalert2";
import { Rating } from "react-simple-star-rating";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { GET_CUSTOMER_DETAILS } from "../../actions";
import { apiUrl, uniqueID } from "../Settings/Config";
import { showPriceSeparate } from "../Helpers/SettingHelper";
import arrow from "../../common/images/success-tick.svg";
import offerimage from "../../common/images/offerimage.png";
import Header from "../Layout/Header";
class ThankyouDirectPay extends Component {
  constructor(props) {
    super(props);
    const UserId = cookie.load("UserId") || "";
    const loginType = cookie.load("loginType") || "";
    var ratingName = '';
    var ratingEmail = '';
    var ratingPhone = '';
    if(loginType !== "Guest") {
      ratingName = cookie.load("UserFname") || "";
      ratingEmail = cookie.load("UserEmail") || "";
      ratingPhone = cookie.load("UserMobile") || "";      
    }
    this.state = {
      designType: "new",

      billPayAmount: "",
      current_page: "Direct Pay Thankyou",
      checkoutType: "BillPay",
      clientLogo: "",
      backLink: "",
      UserId: UserId,
      customerData: [],

      plan_gst_amount: 0,
      plan_grandtotal: 0,
      inititalLoad: true,
      productList: [],
      productName: "",
      gSettings: "",
      paymentDetail: "",
      googleRating: false,

      loginType: loginType,
      ratingAlreadyPost: "No",
      ratingValue: 0,
      ratingName: ratingName,
      ratingEmail: ratingEmail!==""?ratingEmail:'',
      ratingPhone: ratingPhone,
      ratingMessage: "",

      welcomeVoucher: [],
    };
    if (UserId === "") {
      props.history.push("/");
    }
    this.submitRef = React.createRef();
    this.loadWelcomeVoucher();
    this.props.getCustomerDetails("&customer_id=" + UserId);
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    cookie.remove("DirectQRCode", { path: "/" });
    var billPayAmount = this.props.location.state?.billPayAmount || "";
    if (billPayAmount !== "") {
      this.loadDatas();
    } else {
      this.checkPaymentStatus();
    }
    this.loadSettings();
    console.log(this.props, "this.props");

    /* */
  }
  componentWillReceiveProps(PropsDt) {
    if (this.state.customerData !== PropsDt.customerdetails) {
      this.setState({ customerData: PropsDt.customerdetails });
    }
  }

  loadWelcomeVoucher() {
    var customerID = cookie.load("UserId") || "";
    var urlShringTxt = `${apiUrl}product/voucherList?uniqueID=${uniqueID}&productType=7&welcomeVoucher=Yes&customerID=${customerID}`;
    axios
      .get(urlShringTxt, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({
            welcomeVoucher: res.data.result,
          });
        }
      });
  }

  gotoWelcomeVoucher(selectedVoucher) {
    this.props.history.push("/buy-vouchers/" + selectedVoucher.productslug);
  }

  checkPaymentStatus() {
    var urlParams = new URLSearchParams(this.props.location.search);
    var transactionID = urlParams.get("transaction_id") || "";
    var rapydTransactionID = urlParams.get("rapydTransactionID") || "";

    var postObject = {};
    if(transactionID!=="") {
      postObject = {
        uniqueID: uniqueID,
        payment_refID: transactionID,
        customer_id: this.state.UserId,
      };
      axios
        .post(apiUrl + "payment/checkStatusReddot", postObject, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          if (res.data.status === "ok") {
            const billData = res.data.billData;
            const ratingAlreadyPost = billData.ratingAlreadyPost;
            var ratingValue = 0;
            if (ratingAlreadyPost === "Yes") {
              ratingValue = billData.ratingNumber;
            }
  
            this.setState({
              paymentDetail: res.data.billData,
              ratingValue: ratingValue,
              ratingAlreadyPost: ratingAlreadyPost,
            });
          } else {
            Swal.fire({
              title: "Error",
              html: res.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary waves-effect waves-light",
              },
              buttonsStyling: false,
            });
            this.props.history.push("/directpay/checkout");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }else if(rapydTransactionID!=='') {
      postObject = {
        uniqueID: uniqueID,
        payment_refID: rapydTransactionID,
        customer_id: this.state.UserId,
      };
      axios
        .post(apiUrl + "payment/checkStatusRapyd", postObject, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          if (res.data.status === "ok") {
            const billData = res.data.billData;
            const ratingAlreadyPost = billData.ratingAlreadyPost;
            var ratingValue = 0;
            if (ratingAlreadyPost === "Yes") {
              ratingValue = billData.ratingNumber;
            }
  
            this.setState({
              paymentDetail: res.data.billData,
              ratingValue: ratingValue,
              ratingAlreadyPost: ratingAlreadyPost,
            });
          } else {
            Swal.fire({
              title: "Error",
              html: res.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary waves-effect waves-light",
              },
              buttonsStyling: false,
            });
            this.props.history.push("/directpay/checkout");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    
  }

  loadDatas() {
    var state = this.props.location.state || "";
    var voucherList = state?.voucherList || [];
    var selectedVoucher = state?.selectedVoucher;
    let paymentDetail = {
      outletName: state?.companyName,
      reciveAmount: state?.reciveAmount,
      total_amount: state?.plan_grandtotal,
      billPayAmount: state?.billPayAmount,
      voucherBillAmount: state?.voucherBillAmount,
      outletPlaceID: state?.outletPlaceID || '',
      gstAmount: 0,
      productID:
        selectedVoucher !== "" ? voucherList[selectedVoucher].product_id : "",
      productVoucherExpiryDays:
        selectedVoucher !== "" ? voucherList[selectedVoucher].productVoucherExpiryDays : "",
    };
    this.setState({ paymentDetail: paymentDetail });
  }

  loadSettings() {
    var urlShringTxt = apiUrl + "settings/getSettings/?uniqueID=" + uniqueID;
    axios
      .get(urlShringTxt, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var result = res.data.result;

          this.setState({
            gSettings: result,
            clientLogo: result?.company_logo,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  handleRating(rate) {
    if (this.state.ratingAlreadyPost === "No") {
      this.setState({ ratingValue: rate });
    }
  }


  submitReview(e) {
    e.preventDefault();
    if (this.state.ratingValue > 3) {
      this.submitRating();
    } else {
      this.setState({ googleRating: true });
    }
  }

  handleFldChange(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }

  submitRating(e) {
    var event = e ?? '';
    if(event!=="") {
      event.preventDefault();
    }    
    const postObject = {
      uniqueID: uniqueID,
      customerID: this.state.UserId,
      ratingNumber: this.state.ratingValue,
      ratingName: this.state.ratingName,
      ratingEmail: this.state.ratingEmail,
      ratingPhone: this.state.ratingPhone,
      ratingMessage: this.state.ratingMessage,
      outletID: cookie.load("outletID") || "",
      transactionID:this.props.location.state?.transactionID || ""
    };

    axios
      .post(apiUrl + "rating/submitRating", postObject, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ googleRating: false, ratingAlreadyPost: "Yes" });

          const outletPlaceID = this.state.paymentDetail?.outletPlaceID || "";
          if (this.state.ratingValue > 3 && outletPlaceID !== "") {
            window.open(
              `https://search.google.com/local/writereview?placeid=${outletPlaceID}`,
              "_blank"
            );
          } else {
            Swal.fire({
              title: "Success",
              html: res.data.message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary waves-effect waves-light",
              },
              buttonsStyling: false,
            });
          }
        }
      })
      .catch((e) => {
        this.setState({ googleRating: false });
        var errorMsg = e?.response?.data?.massage || e.message;
        Swal.fire({
          title: "Error",
          html: errorMsg,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary waves-effect waves-light",
          },
          buttonsStyling: false,
        });

        console.error("Error fetching data:", e);
      });
  }

  render() {
    if (this.state.gSettings !== "" && this.state.paymentDetail !== "") {
      return (
        <div className="directpay-new home-page">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div className="container">
            <div className="pament-success">
              <div className="payment-confirmation">
                <div className="success-heading">
                  <div className="icon">
                    <img src={arrow} alt="" />
                  </div>

                  <h2>
                    Thank You For Your <br /> Payment
                  </h2>
                </div>
                <div className="details">
                  <div className="table-sec">
                    {/*  <div className="table-no">Table 5</div> */}
                    {this.state.paymentDetail?.outletName !== "" && (
                      <p>{this.state.paymentDetail?.outletName}</p>
                    )}
                  </div>
                  {/*  <div className="payment-info">
                    <div className="paid-info">
                      <div>
                        <strong>{this.state.paymentDetail.proName}</strong>
                      </div>
                      <div>
                        {" "}
                        <span>
                          1 X{" "}
                          {showPriceValue(
                            this.state.paymentDetail.subtotal_amount
                          )}
                        </span>
                      </div>
                    </div>
                     <p>You payed for your share</p>
                  </div> */}
                  {this.state.paymentDetail?.productID !== "" && (
                    <>
                      <div className="payment-info">
                        <div className="paid-info">
                          <div>
                            <strong>You Pay</strong>
                          </div>
                          <div>
                            {" "}
                            <span>
                              {" "}
                              {showPriceSeparate(
                                this.state.paymentDetail?.total_amount
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {this.state.paymentDetail?.reciveAmount !== "" && (
                        <div className="payment-info">
                          <div className="paid-info">
                            <div>
                              <strong>Receive</strong>
                            </div>
                            <div>
                              {" "}
                              <span>
                                {showPriceSeparate(
                                  this.state.paymentDetail?.reciveAmount || 0
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <div className="payment-info">
                    <div className="paid-info">
                      <div>
                        <strong>Bill</strong>
                      </div>
                      <div>
                        {" "}
                        <span>
                          {showPriceSeparate(
                            this.state.paymentDetail.billPayAmount
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {(parseFloat(this.state.paymentDetail?.voucherBillAmount)>0 && (
                      <div className="payment-info">
                        <div className="paid-info">
                          <div>
                            <strong>Redeem Voucher Balance</strong>
                          </div>
                          <div>
                            {" "}
                            <span>
                              -{showPriceSeparate(
                                this.state.paymentDetail?.voucherBillAmount || 0
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )                    
                  )}
                  {parseFloat(this.state.paymentDetail?.gstAmount || 0) > 0 && (
                    <div className="payment-info">
                      <div className="paid-info">
                        <div>
                          <strong>GST</strong>
                        </div>
                        <div>
                          <span>
                            {showPriceSeparate(
                              this.state.paymentDetail?.gstAmount
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.paymentDetail?.productID !== "" && (
                    <div className="payment-info">
                      <div className="paid-info">
                        <div>
                          <strong>Balance For Next Visit</strong>
                          <label className="expiry-days">Expires in {this.state.paymentDetail.productVoucherExpiryDays} days</label>
                        </div>
                        <div>
                          <span>
                            {showPriceSeparate(
                              this.state.paymentDetail?.reciveAmount -
                                this.state.paymentDetail.billPayAmount
                            )}
                          </span>
                          
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="payment-info">
                    <div className="paid-info">
                      <div>
                        <strong>Total</strong>
                      </div>
                      <div>
                        <span>
                          {showPriceSeparate(
                            this.state.paymentDetail.total_amount
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="review-section">
                <h2>
                  Please Give Us Your Review
                </h2>
                <p>How's your overall experience?</p>
                <div className="stars">
                  <Rating
                    initialValue={this.state.ratingValue}
                    onClick={this.handleRating.bind(this)}
                    allowHover={
                      this.state.ratingAlreadyPost === "Yes" ? false : true
                    }
                  />
                </div>
                {this.state.ratingAlreadyPost === "No" && (
                  <a
                    href={"/"}
                    onClick={this.submitReview.bind(this)}
                    className="review-btn"
                  >
                    Review This Place
                  </a>
                )}
              </div>
              <div className="voucher-section">
                {this.state.welcomeVoucher.length > 0 &&
                  this.state.welcomeVoucher.map((item) => {
                    return (
                      <div className="voucher-card">
                        <img
                          src={item?.productThumbnail || offerimage}
                          alt="Breakfast offer"
                          className="thanks-voucher-image"
                          onClick={this.gotoWelcomeVoucher.bind(this, item)}
                        />
                        <a
                          href="/"
                          className={`voucher-btn ${
                            item.alreadyredeem === "yes" ? "used" : ""
                          }`}
                          onClick={this.gotoWelcomeVoucher.bind(this, item)}
                        >
                          {item.alreadyredeem === "yes"
                            ? "Already issued"
                            : "Claim Your Free Welcome Voucher"}
                        </a>
                      </div>
                    );
                  })}
              </div>
              {/*  <div className="voucher-section">
                <div className="voucher-card">
                  <img
                    src={offerimage}
                    alt="Voucher"
                    className="thanks-voucher-image"
                  />
                  <a href="#" className="voucher-btn">
                    Discover Our Vouchers
                  </a>
                </div>
                <div className="voucher-card">
                  <img
                    src={voucher2}
                    alt="Birthday Voucher"
                    className="thanks-voucher-image"
                  />
                  <a href="#" className="voucher-btn">
                    Claim Your Birthday Voucher
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          <BottomSheet
            open={this.state.googleRating}
            onDismiss={() => {
              this.setState({ googleRating: false });
            }}
          >
            <div className="container">
              <div className="update-personal internal-rating">
                <div className="up-header textcenter">
                  <p>How’s your overall experience?</p>
                  <Rating
                    initialValue={this.state.ratingValue}
                    onClick={this.handleRating.bind(this)}
                  />
                </div>
                <div className="up-form">
                  <input
                    type="text"
                    placeholder="Name"
                    name="ratingName"
                    value={this.state.ratingName}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  <input
                    type="text"
                    placeholder="Email Address"
                    name="ratingEmail"
                    value={this.state.ratingEmail}
                    onChange={this.handleFldChange.bind(this)}
                  />

                  <input
                    type="text"
                    placeholder="Contact Number"
                    name="ratingPhone"
                    value={this.state.ratingPhone}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  <textarea
                    placeholder="Let us know your feedback"
                    name="ratingMessage"
                    onChange={this.handleFldChange.bind(this)}
                  >
                    {this.state.ratingMessage}
                  </textarea>
                  {this.state.regcust_error !== "" && (
                    <p className="error_info">{this.state.regcust_error}</p>
                  )}
                  <div className="up-frm-btn">
                    <a
                      href="/"
                      className="button cust-reg-btn"
                      onClick={this.submitRating.bind(this)}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </BottomSheet>
        </div>
      );
    } else {
      return (
        <div className="container">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div id="loading-indicator">
            <div class="lds-hourglass"></div>
          </div>
        </div>
      );
    }
  }
}

const mapStateTopProps = (state) => {
  var customerdetailsArr = [];
  if (Object.keys(state.customerdetails).length > 0) {
    if (state.customerdetails[0].status === "ok") {
      customerdetailsArr = state.customerdetails[0].result_set;
    }
  }
  return {
    customerdetails: customerdetailsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAILS, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(ThankyouDirectPay));
