/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_TOPUPPLAN_LIST, SET_TOPUPPLAN_LIST } from "../actions";
import { apiUrl, uniqueID } from "../components/Settings/Config";
import Axios from "axios";

export const watchGetTopupplanList = function* () {
  yield takeEvery(GET_TOPUPPLAN_LIST, workerGetTopupplanList);
};

function* workerGetTopupplanList(reqData) {
  try {
    const uri =
      apiUrl + "topupplan/planlist?uniqueID=" + uniqueID + "" + reqData.params;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_TOPUPPLAN_LIST, value: resultArr });
  } catch (e) {
    let returnerror = [];
    returnerror.push(e?.response?.data || []);
    yield put({ type: SET_TOPUPPLAN_LIST, value: returnerror });
  }
}
