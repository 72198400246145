import React, { Component } from "react";
import axios from "axios";
import Header from "../Layout/Header";
import { apiUrl } from "../Settings/Config";
var Parser = require("html-react-parser");
class Privacypolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: "Privacy Policy",
      backLink: "myaccount",
      pageContent: "",
    };
  }
  componentDidMount() {
    this.loadpageDetails();
  }

  loadpageDetails() {
    var urlShringTxt = apiUrl + "cmspage/pagedetails?pageSlug=privacy-policy";
    axios
      .get(urlShringTxt)
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({
            current_page: res.data.result.pageTitle,
            pageContent: res.data.result.pageContent,
          });
        }
      })
      .catch((e) => {
        console.error("Error fetching data:", e);
      });
  }

  render() {
    return (
      <div className="main-div">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel">
          <div className="container">
            <div className="vouchers-list">
              <div className="tier-benefit-body" style={{ marginTop: "20px" }}>
                <div>
                  {this.state.pageContent !== "" &&
                    Parser(this.state.pageContent)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacypolicy;
