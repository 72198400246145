/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../Layout/Header";
import cookie from "react-cookies";
import { BulletList } from "react-content-loader";
import {
  apiUrl,
  uniqueID,
  stripeReference,
} from "../Settings/Config";
import { showPriceValue, userID } from "../Helpers/SettingHelper";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Swal from "sweetalert2";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
var qs = require("qs");
class History extends Component {
  constructor(props) {
    super(props);
    var suscribeID = this.props.match.params?.suscribeID || "";
    this.state = {
      current_page: "Subscription Details",
      suscribeID: suscribeID,
      subscriptionLoad: true,
      subscriptionList: [],
      dispalySubscription: "",
    };
    if (suscribeID === "") {
      props.history.push("/subscription-history");
    }
    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
  }
  componentDidMount() {
    $("body").addClass("hide-overlay");
    this.loadSubscription();
  }

  loadSubscription() {
    if (
      uniqueID !== "" &&
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      axios
        .get(
          apiUrl +
            "subscription/detailsubscription?customer_id=" +
            cookie.load("UserId") +
            "&suscribeID=" +
            this.state.suscribeID,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState(
              {
                subscriptionList: res.data.result,
              },
              function () {
                this.dispalySubscription();
              }
            );
          } else {
            this.setState({ subscriptionLoad: false });
          }
        })
        .catch((error) => {
          this.setState({ subscriptionLoad: false }, () => {
            this.dispalySubscription();
          });
          console.error("Error fetching data:", error);
        });
    }
  }

  dispalySubscription() {
    var dispalySubscription = "";
    if (this.state.subscriptionList.subscribe_history.length > 0) {
      var dispaly_Subscription =
        this.state.subscriptionList.subscribe_history.map((item, index) => {
          return (
            <li key={index}>
              <div className="hlm-lhs">
                <p> {item.subscribe_history_date}</p>
                <span>{item.subscribe_history_status}</span>
              </div>
              <div className="hlm-rhs">
                <strong>
                  {showPriceValue(
                    this.state.subscriptionList.subscribe_grandtotal
                  )}
                </strong>
              </div>
            </li>
          );
        });
      var subscribeDetails = this.state.subscriptionList;
      var subscribePeriod = "";
      if (subscribeDetails.subscribe_period === "W") {
        subscribePeriod = "Weekly";
      } else if (subscribeDetails.subscribe_period === "M") {
        subscribePeriod = "Monthly";
      } else if (subscribeDetails.subscribe_period === "Q") {
        subscribePeriod = "Quarterly";
      } else if (subscribeDetails.subscribe_period === "B") {
        subscribePeriod = "Biannually";
      } else if (subscribeDetails.subscribe_period === "A") {
        subscribePeriod = "Annually";
      }
      var subscribstatus = "";
      if (subscribeDetails.subscribe_status === "A") {
        subscribstatus = "Active";
      } else if (subscribeDetails.subscribe_status === "I") {
        subscribstatus = "Inactive";
      } else if (subscribeDetails.subscribe_status === "C") {
        subscribstatus = "Cancelled";
      } else if (subscribeDetails.subscribe_status === "P") {
        subscribstatus = "Pending";
      }
      var subscribe_vouchers =
        subscribeDetails.subscribe_vouchers !== "" &&
        subscribeDetails.subscribe_vouchers !== null
          ? JSON.parse(subscribeDetails.subscribe_vouchers)
          : [];

      dispalySubscription = (
        <>
          <div>
            <p>
              {" "}
              {subscribeDetails.subscribe_item_qty} X {subscribePeriod}{" "}
              {subscribeDetails.subscribe_item_name}{" "}
            </p>
            <b
              className={
                subscribeDetails.subscribe_status === "C"
                  ? "subscribe-cancel"
                  : ""
              }
            >
              {subscribstatus}
            </b>
            <br />
            <span>
              Started: {subscribeDetails.subscribe_start_date} | Next:{" "}
              {subscribeDetails.subscribe_next_cycle}
            </span>
          </div>

          <ul>{dispaly_Subscription}</ul>
        </>
      );
    } else {
      dispalySubscription = (
        <ul>
          <li>
            <div
              className="hlm-lhs"
              style={{ textAlign: "center", width: "100%" }}
            >
              <p>&nbsp;</p>
              <span>No History Of Subscription</span>
            </div>
          </li>
        </ul>
      );
    }
    this.setState({
      dispalySubscription: dispalySubscription,
      subscriptionLoad: false,
    });
  }
  cancelSubscribe() {
    var currentThis = this;
    var formPayload = {
      subscriptionID: this.state.suscribeID,
      customer_id: cookie.load("UserId"),
      UniqueID: this.state.subscriptionList.subscribe_company_unquie_id,
      payment_reference: stripeReference,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You want cancel this subscription!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, cancel it!",
      customClass: {
        confirmButton: "btn btn-primary me-3 waves-effect waves-light",
        cancelButton: "btn red-btn btn-label-secondary waves-effect",
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return axios
          .post(
            apiUrl + "subscription/cancelSubscription",
            qs.stringify(formPayload),
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          }).catch((e) => {
            console.error("Error fetching data:", e);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.value !== "" && typeof result.value !== undefined) {
        if (result.value.status == "ok") {
          currentThis.loadSubscription();
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: result.value.message,
            customClass: {
              confirmButton: "btn btn-success waves-effect",
            },
          });
        } else {
          Swal.fire({
            title: "Alert",
            text: result.value.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger waves-effect",
            },
          });
        }
      }
    });
  }

  render() {
    return (
      <div className="main-div trans-page-inner">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel">
          <div className="container">
            <div className="history-list">
              <div className="history-filter"></div>
              <div className="history-list-main">
                <br />
                {this.state.subscriptionLoad === true ? (
                  <BulletList
                    backgroundColor={"#c7c7c7"}
                    foregroundColor={"#c7c7c7"}
                    title="Fetching Your Subscriptions"
                  />
                ) : (
                  this.state.dispalySubscription
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.dispalySubscription !== "" &&
          this.state.subscriptionList?.subscribe_status !== "C" && (
            <IonFooter collapse="fade">
              <div className="sticky-single-btn">
                <a
                  href={void 0}
                  className="button red-btn place-order-link"
                  onClick={this.cancelSubscribe.bind(this)}
                >
                  Cancel Subscription
                </a>
              </div>
            </IonFooter>
          )}
      </div>
    );
  }
}

export default History;
