import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Upload,
  ImageIcon,
  RefreshCw,
  ReceiptIcon,
  XCircle,
  Camera,
  
} from "lucide-react";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import axios from "axios";
import {
  hideLoader,
  showLoader,
  showPriceSeparate,
  showPriceValue,
} from "../Helpers/SettingHelper";
import { apiUrl, uniqueID } from "../Settings/Config";
import { Toaster, toast } from "sonner";

const ReceiptUploader = ({ props }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cashBackAmount, setCashBackAmount] = useState(0);
  const [uploadedData, setUploadData] = useState({
    items: [],
    total: 0,
    tax: 0,
    roundoff: 0,
    date: "",
    merchantName: "",
    otherchargeList: [],
    currency: "SGD",
  });
  const [billID, setBillID] = useState(""); 
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [photo, setPhoto] = useState(null);
  const [howCamera, setShowCamera] = useState(false);
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };
  const stopCamera = async () => {
    if (videoRef.current && videoRef.current.srcObject) {
      let stream = videoRef.current.srcObject;
      let tracks = stream.getTracks(); // Get all tracks (video/audio)

      tracks.forEach((track) => track.stop()); // Stop each track
      videoRef.current.srcObject = null; // Remove video source
    }
    setShowCamera(false);
  };

  const showCameraView = async () => {
    setShowCamera(true);
    startCamera();
  };

  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (video && canvas) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      setPhoto(canvas.toDataURL("image/png")); // Convert to base64 image
    }
  };

  useEffect(() => {
    if (photo !== null) {
      setShowCamera(false);
      stopCamera();
      processImage(photo);
    }
  }, [photo]);

  const fileInputRef = useRef(null);

  const processImage = useCallback(async (file) => {
    if (photo === null) {
      if (!file.type.startsWith("image/")) {
        toast.error("Please upload an image file");
        return;
      }
    }

    try {
      setIsProcessing(true);

      toast.info("Processing receipt image...");
      console.log(photo, "photophotophoto");
      if (photo === null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setSelectedImage(e.target?.result);
        };
        reader.readAsDataURL(file);
      }
     /*  return false; */
      const formData = new FormData();
      if (photo !== null) {
        const blob = await fetch(photo).then((res) => res.blob());
        // Append Blob to FormData
        formData.append("billRecipt", blob, "captured-image.png");
      } else {
        formData.append("billRecipt", file);
      }
      formData.append("uniqueID", uniqueID);

      axios
        .post(apiUrl + "bill/scanBill", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === "ok") {
            setUploadData(res.data.analysisResult.receiptData);
            setCashBackAmount(res.data.cashBackAmount);
            setBillID(res.data.billID);
            setIsProcessing(false);
            toast.success("Successfully scanned");
          }
        })
        .catch((e) => {
          console.error("Error fetching data:", e);
          const errorMsg = e?.response?.data?.message ?? e?.message;
          toast.error(errorMsg, {
            icon: <XCircle size={24} />,
            style: {
              background: "#ff4d4f",
              color: "#fff",
            },
          });
          setIsProcessing(false);
        });
    } catch (error) {
      console.error("Error processing image:", error);
      toast.error("An error occurred while processing the image");
      setIsProcessing(false);
    }
  });

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      processImage(files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      processImage(files[0]);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const handleReset = () => {
    setSelectedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setUploadData({
      items: [],
      subtotal: 0,
      total: 0,
      tax: 0,
      roundoff: 0,
      dicount: 0,
      date: "",
      merchantName: "",
      otherchargeList: [],
      currency: "SGD",
    });
  };

  const confirmRecipt = (e) => {
    e.preventDefault();
    const postData = {
      uniqueID: uniqueID,
      billID: billID,
    };
    showLoader("confirmRecipt");
    axios
      .post(apiUrl + "bill/confirmBill", postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        hideLoader("confirmRecipt");
        if (res.data.status === "ok") {
          props.history.push({
            pathname: "/bill-scan/thankyou",
            state: { billID: res.data.billID, transactionID:res.data.transactionID},
          });

          setUploadData(res.data.analysisResult.receiptData);
          setIsProcessing(false);
          toast.success("Successfully scanned");
        }
      })
      .catch((e) => {
        console.error("Error fetching data:", e);
        setIsProcessing(false);
        const errorMsg = e?.response?.data?.message ?? e?.message;
        toast.error(errorMsg, {
          icon: <XCircle size={24} />,
          style: {
            background: "#ff4d4f",
            color: "#fff",
          },
        });
        hideLoader("confirmRecipt");
      });
  };

  return (
    <div>
      <Toaster />
      <div className="container">
        {howCamera === true && (
          <div className="flex flex-col items-center space-y-4 p-4">
            <div className="video-camera-main">
            <button
              className="reset-btn"
              onClick={stopCamera}
            >
              <XCircle className="h-4 w-4 mr-1" />
            </button>
            <video
              ref={videoRef}
              autoPlay
              playsInline
              className="video-camera"
            />
            </div>
            <canvas ref={canvasRef} className="hidden" />
            <div className="capture-btn">
              <button
                onClick={capturePhoto}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Capture Photo
              </button>
            </div>
          </div>
        )}

        <div
          className="w-full max-w-xl mx-auto mt-6 animate-fade-in-up recipt-page"
          style={{ animationDelay: "0.1s" }}
        >
          <div
            className={`glass-panel p-8 mb-6 flex flex-col items-center justify-center border-2 border-dashed rounded-xl transition-all duration-200 ${
              isDragging
                ? "border-primary bg-primary/5"
                : "border-muted hover:border-muted-foreground/30"
            } ${isProcessing ? "opacity-70 pointer-events-none" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{ minHeight: "280px", marginTop: "30px" }}
          >
            <div className="upload-container">
              <div className="upload-box" id="drop-area">
                {selectedImage !== null || photo !== null ? (
                  <div id="image-preview">
                    <img
                      id="preview-img"
                      src={photo !== null ? photo : selectedImage}
                      alt="Receipt Preview"
                    />
                    <button
                      className="reset-btn"
                      onClick={handleReset}
                      disabled={isProcessing}
                    >
                      <RefreshCw className="h-4 w-4 mr-1" /> Reset
                    </button>
                    {isProcessing && (
                      <div id="loading-overlay">
                        <div className="spinner"></div>
                        <p>Processing receipt...</p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div id="upload-content">
                    <ImageIcon alt="Upload Icon" className="upload-icon" />
                    <p className="upload-text">Upload Receipt Image</p>
                    <p className="upload-instructions">
                      Drag and drop an image of your receipt, or click to browse
                      files. <br />
                      Supported formats: JPEG, PNG, HEIC.
                    </p>
                    <input
                      type="file"
                      id="file-input"
                      accept="image/*"
                      hidden
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      className="hidden"
                    />
                    <div className="upload-img-div">
                      <button
                        className="upload-button"
                        id="upload-btn"
                        onClick={triggerFileInput}
                      >
                        {" "}
                        <Upload className="h-4 w-4" />
                        Select Image
                      </button>
                      <button
                        className="upload-button"
                        onClick={showCameraView}
                      >
                        {" "}
                        <Camera className="h-4 w-4" />
                        View Camera
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {parseFloat(uploadedData.total) > 0 && (
            <div className="cart-detail">
              <div className="cart-detail-header">
                <h3>Receipt Summary</h3>
              </div>
              <div className="table-sec">
                <p>Order Date: {uploadedData.date}</p>
              </div>
              <div className="cart-detail-body">
                {uploadedData.items.length > 0 && (
                  <>
                    <div className="table-sec">
                      <div className="table-no">
                        <ReceiptIcon className="h-4 w-4 mr-2 text-muted-foreground" />
                        Items Purchased
                      </div>
                    </div>

                    {uploadedData.items.map((item, index) => {
                      return (
                        <div className="cart-detail-voucher" key={index}>
                          <div className="cart-detail-voucher-img">
                            <span>{item.name}</span>
                          </div>
                          <div className="cart-detail-voucher-desc">
                            <div className="qty-bx">
                              <div className="input-quantity"></div>
                            </div>
                            <div className="cart-price">
                              {item.quantity} X {showPriceValue(item.price)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
                <ul>
                  {parseFloat(uploadedData.subtotal) > 0 && (
                    <li>
                      <span>Sub Total</span>
                      <strong>
                        {showPriceSeparate(uploadedData.subtotal)}
                      </strong>
                    </li>
                  )}
                  {uploadedData.otherchargeList.length > 0 &&
                    uploadedData.otherchargeList.map((item, index) => {
                      return (
                        <li key={index}>
                          <span>{item.chargename}</span>
                          <strong>{showPriceSeparate(item.amount)}</strong>
                        </li>
                      );
                    })}
                  {parseFloat(uploadedData.dicount) > 0 && (
                    <li>
                      <span>Discount</span>
                      <strong>{showPriceSeparate(uploadedData.dicount)}</strong>
                    </li>
                  )}
                  {parseFloat(uploadedData.tax) > 0 && (
                    <li>
                      <span>GST</span>
                      <strong>{showPriceSeparate(uploadedData.tax)}</strong>
                    </li>
                  )}

                  {parseFloat(cashBackAmount) > 0 && (
                    <li>
                      <span>
                        <b>Cash Back</b>
                      </span>
                      <strong>
                        <b>{showPriceSeparate(cashBackAmount)}</b>
                      </strong>
                    </li>
                  )}
                  {parseFloat(uploadedData.roundoff) > 0 && (
                    <li>
                      <span>Round OFF</span>
                      <strong>
                        {showPriceSeparate(uploadedData.roundoff)}
                      </strong>
                    </li>
                  )}

                  <li className="ts-total">
                    <span>Total</span>
                    <strong>{showPriceSeparate(uploadedData.total)}</strong>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        {parseFloat(uploadedData.total) > 0 && billID !== "" && (
          <IonFooter collapse="fade">
            <div className="sticky-single-btn">
              <a
                href="/"
                className="button green-btn place-order-link"
                id="confirmRecipt"
                onClick={confirmRecipt}
              >
                Confirm Receipt
              </a>
            </div>
          </IonFooter>
        )}
      </div>
    </div>
  );
};

export default ReceiptUploader;
