/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_PRODUCT_LIST, SET_PRODUCT_LIST } from "../actions";
import { apiUrl } from "../components/Settings/Config";
import Axios from "axios";

export const watchGetProductList = function* () {
  yield takeEvery(GET_PRODUCT_LIST, workerGetProductList);
};

function* workerGetProductList(reqData) {
  try {
    const uri = apiUrl + "product/voucherList?" + reqData.params;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PRODUCT_LIST, value: resultArr });
  } catch (e) {
    let returnerror = [];
    returnerror.push(e?.response?.data || []);
    yield put({ type: SET_PRODUCT_LIST, value: returnerror });
  }
}
