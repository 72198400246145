/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import cookie from "react-cookies";
import { apiUrl, uniqueID } from "../Settings/Config";
import { showLoaderLst, hideLoaderLst, showAlert } from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
var qs = require("qs");

class RedeemQRCode extends Component {
  constructor(props) {
    super(props);
    var usercredits =
      localStorage.getItem("usercredits") === null
        ? 0
        : localStorage.getItem("usercredits");
    var scanType = localStorage.getItem("scanType") || '';
    this.state = {
      current_page: "Redeem Credits",
      qr_details: [],
      qr_detail_error: "",
      user_credits: usercredits,
      redeemqr_error: "",
      redeemAmount: this.props.location.state?.redeemAmount || "",
      scanType:scanType,
    };
  }
  componentDidMount() {
    var qrcodetxt =
      localStorage.getItem("qrcodetxt") === null
        ? ""
        : localStorage.getItem("qrcodetxt");
    if (qrcodetxt !== "") {
      localStorage.removeItem("qrcodetxt");
       if(this.state.scanType==="paybill") {
        
        localStorage.removeItem("scanType");
        var qrcodetxtSplit = qrcodetxt.split('-');
        let postObject = this.props.location.state;
        cookie.save('outletID', qrcodetxtSplit[2], { path: "/" })
        postObject.outletID = qrcodetxtSplit[2];
        postObject.directQRCode = qrcodetxt;
        console.log(postObject, 'postObjectpostObjectpostObject')
        axios
          .post(
            apiUrl + "paymentV2/processReddotPayment",
            qs.stringify(postObject),
            {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            }
          )
          .then((res) => {
            if (res.data.status === "ok") {
              localStorage.setItem(
                "paymentRequestIdRedtod",
                res.data.payment_requestId
              );
              window.location.href = res.data.redirectURL;
            } else {
              this.props.history.push('directpay/checkout')
              showAlert("Alert", res.data.message, "error");
            }
          }).catch((e) => {
            console.error("Error fetching data:", e);
          });
       }else {
        this.getVoucherData(qrcodetxt);
       }
      
    } else {
      this.props.history.push("/");
    }
  }

  getVoucherData(qrCodeVal) {
    var postObject = {
      uniqueID: uniqueID,
      QRCode: qrCodeVal,
      redeemAmount: this.state.redeemAmount,
    };
    showLoaderLst("redeem-page-main", "class");
    axios
      .post(apiUrl + "customerQR/readCompnayQrcode", postObject, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        hideLoaderLst("redeem-page-main", "class");
        if (res.data.status === "ok") {
          this.setState({ qr_details: res.data.result, redeemAmount:res.data.result[0].QRAmount });
        } else {
          this.setState({ qr_details: [], qr_detail_error: "yes" });
        }
      })
      .catch((e) => {
        hideLoaderLst("redeem-page-main", "class");
        var errorMsg = e?.response?.data?.message || e.message;
        Swal.fire({
          title: "Error",
          html: errorMsg,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary waves-effect waves-light",
          },
          buttonsStyling: false,
        });
        this.props.history.push("/myaccount");
        console.error("Error fetching data:", e);
      });
  }

  goBackTo(event) {
    event.preventDefault();
    this.props.history.push("/myaccount");
  }

  comfirmRedeemFun(event) {
    event.preventDefault();
    let qrDetails = this.state.qr_details;
    let userCredits = qrDetails[0].QRAmount;
    console.log(qrDetails, 'qrDetails')
    if (qrDetails.length > 0  && parseFloat(userCredits) > 0) {
      /* var postObject = {
        uniqueID: qrDetails[0].cust_qr_app_id,
        cust_qr_str: qrDetails[0].cust_qr_str,
        cust_qr_id: qrDetails[0].cust_qr_primary_id,
        user_outlet_id: qrDetails[0].cust_qr_outletID,
        user_id: cookie.load("UserId"),
        transUserID: qrDetails[0].cust_qr_user_id,
        user_credits: userCredits,
      }; */

      
      var postObject = {
        uniqueID: uniqueID,
        customerID: cookie.load("UserId"),
        billPayAmount: qrDetails[0].QRAmount,
        outletID:qrDetails[0].QROultetID,
        qrcode: qrDetails[0]?.QRCode,
        paymentMethod: '',
        checkoutType: "redeem",
      };

      showLoaderLst("redeem-btn-cls", "class");
      axios
        .post(apiUrl + "payment/billPay", postObject, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          hideLoaderLst("redeem-btn-cls", "class");
          if (res.data.status === "ok") {
            Swal.fire({
              icon: "success",
              title: "Redeemed!",
              text: "Cash Voucher has been redeemed succuessfully.",
              customClass: {
                confirmButton: "btn btn-success waves-effect",
              },
            });
            this.props.history.push("/myaccount");
          } else {
            this.setState({ redeemqr_error: res.data.message });
          }
        }).catch((e) => {
          hideLoaderLst("redeem-btn-cls", "class");
          var errorMsg = e?.response?.data?.message || e.message;
          Swal.fire({
            title: "Error",
            html: errorMsg,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
          console.error("Error fetching data:", e);
        });
    } else {
      this.setState({ redeemqr_error: "Invalide QR" });
    }
  }

  render() {
    let qrDetails = this.state.qr_details;
    console.log(qrDetails, 'qrDetailsqrDetails')
    return (
      <div className="main-div redeem-page-main">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel redeem-page-inner">
          <div className="container">
            {qrDetails.length > 0 ? (
              <div className="redeem-profie">
                <div className="redeem-profie-box">
                  <figure></figure>
                  <figcaption>
                    <h2>{qrDetails[0].companyName}</h2>
                    <h3>{qrDetails[0].outlet_name}</h3>                    
                  </figcaption>
                </div>

                <div className="redeem-profie-points textcenter">
                  <p>Credits to Redeem</p>
                  <strong>
                    $
                    {this.state.redeemAmount}
                  </strong>
                  {qrDetails[0]?.earnAmount !== "" && (
                    <div className="credit-after-reddem">You are entitled to <span>{qrDetails[0]?.earnPercentage}%</span> discount.<br/>Deduct <span>${this.state.redeemAmount-qrDetails[0]?.earnAmount}</span> Credits Today.</div>
                  )}
                </div>
                {this.state.redeemqr_error != "" && (
                  <p className="error_info">{this.state.redeemqr_error}</p>
                )}
                <div className="redeem-profie-btn textcenter">
                  <a
                    href={void 0}
                    className="button ghost-btn"
                    onClick={this.goBackTo.bind(this)}
                  >
                    Cancel
                  </a>
                  <a
                    href={void 0}
                    className="button redeem-btn-cls"
                    onClick={this.comfirmRedeemFun.bind(this)}
                  >
                    Confirm
                  </a>
                </div>
              </div>
            ) : (
              <div className="redeem-profie invalide-qr-div">
                {this.state.qr_detail_error == "yes" && (
                  <>
                    <div className="redeem-profie-box">
                      <div className="invalide-qr-cls">
                        <h2>Invalide QR Code</h2>
                        <span>
                          Sorry This voucher can not be reddemm at this outlet
                        </span>
                      </div>
                    </div>
                    <div className="redeem-profie-btn textcenter">
                      <a
                        href={void 0}
                        className="button ghost-btn"
                        onClick={this.goBackTo.bind(this)}
                      >
                        Try Agin
                      </a>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default RedeemQRCode;
